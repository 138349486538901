import { countries } from '../utils/IwFormCountry'

export const useInputPhoneUtils = () => {
    /** Represent the returned value if the 'N/A' value is chosen */
    const notApplicableOptionVal = '' as const

    /**
     * Extract out country code from the given phone number
     *
     * @returns Extracted phone country code
     * @returns `null` if the country code is invalid
     */
    function getCountryCodeFromPhoneNumber(
        phoneNumber: string
    ): IwFormPhoneCountryCode | null {
        for (const countryData of Object.values(countries)) {
            if (phoneNumber.startsWith(countryData.countryCode)) {
                return countryData.countryCode
            }
        }

        return null
    }

    /**
     * Get default country code based on available country code list
     */
    function getDefaultCountryCode(
        countryISOCodeList: Array<IwFormCountryISOCode>,
    ): IwFormPhoneCountryCodeWithUnknown {
        if (countryISOCodeList.includes('MYS')) {
            // Return 'MYS' if available
            return countries['MYS'].countryCode
        } else if (countryISOCodeList.length > 0) {
            // Return the first country code
            return countries[countryISOCodeList[0]].countryCode
        } else {
            // Return 'N/A' option
            return notApplicableOptionVal
        }
    }

    /**
     * Check whether or not the given country code can be found in the given list
     */
    function findCountryCode(
        countryCodeList: Array<IwFormCountryISOCode>,
        countryCode: string,
    ) {
        for (const ISOCode of countryCodeList) {
            if (countries[ISOCode].countryCode === countryCode) {
                return true
            }
        }
        return false
    }

    /**
     * Process phone number to keep them in a normalised format
     */
    function normalisePhoneNumber(
        phoneNumber: string,
        countryCode: IwFormPhoneCountryCodeWithUnknown
    ): string {
        // Remove any non-digit characters
        let phone = phoneNumber.replace(/[^0-9]+/g, '')

        switch (countryCode) {
            case '60':
                phone = phone.replace(/^0+/, '')
                break
        }

        return phone
    }

    /**
     * Parse and return the given initialisation data
     */
    function parseInitData(
        countryCode?: IwFormPhoneCountryCodeWithUnknown | null,
        phoneNumber?: string | null,
    ): { countryCode: IwFormPhoneCountryCodeWithUnknown, phoneNumber: string } {
        if (!phoneNumber) {
            return {
                countryCode: countryCode ?? notApplicableOptionVal,
                phoneNumber: '',
            }
        }

        if (!countryCode) {
            const countryCodeFound = getCountryCodeFromPhoneNumber(phoneNumber)
            if (countryCodeFound) {
                countryCode = countryCodeFound
                phoneNumber = phoneNumber.slice(countryCodeFound.length)
            } else {
                countryCode = notApplicableOptionVal
            }
        }

        return {
            countryCode,
            phoneNumber: normalisePhoneNumber(phoneNumber, countryCode),
        }
    }

    return {
        notApplicableOptionVal,

        getCountryCodeFromPhoneNumber,
        getDefaultCountryCode,
        findCountryCode,
        normalisePhoneNumber,
        parseInitData,
    }
}

export default useInputPhoneUtils
