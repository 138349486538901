export const useProgressBarStore = defineStore('progressBar', {
    state: () => ({
        isLoading: false,
    }),
    actions: {
        start() {
            this.isLoading = true
        },
        finish() {
            this.isLoading = false
        },
    },
})

export default useProgressBarStore
