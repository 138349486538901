import { type Attributes, mergeAttributes } from '@tiptap/vue-3'

/**
 * Transform `Node` or `Mark` `addAttributes` list to generated HTML attributes
 *
 * ### Example
 * ```ts
 * // Attribute list used by a TipTap `Node` / `Mark`
 * const attrList = {
 *   width: {
 *     default: 'auto',
 *     parseHTML: (e) => e.style.width,
 *     renderHTML: ({ width }) => {
 *       return width ? { style: `width:${width}` } : null
 *     },
 *   },
 *   textAlign: {
 *     renderHTML: ({ textAlign }) => {
 *       return textAlign ? { style: `text-align:${textAlign}` } : null
 *     },
 *   },
 * }
 *
 * // Attribute values to configure the `Node` / `Mark`
 * const attrValues = {
 *   width: '50%',
 * }
 *
 * generateHtmlAttributes(
 *   attrList,
 *   attrValues,
 *   ['textAlign'],
 * )
 * // returns { style: 'width:50%' }
 * ```
 */
export function generateHtmlAttributes<RetT extends Record<string, any>>(
    attrList: Attributes | {},
    attrValueObj: Record<string, any>,
    attrsToBeIgnored: Array<string | RegExp> = [],
): RetT {
    let htmlAttrList: Record<string, any> = {}

    for (const [attrName, attrObj] of Object.entries(attrList)) {
        const isAttrShouldBeIgnored = attrsToBeIgnored.find(attrToIgnore =>
            attrToIgnore instanceof RegExp
                ? attrToIgnore.test(attrName)
                : attrName === attrToIgnore
        ) != null
        if (isAttrShouldBeIgnored) continue

        const obj = Object.assign({}, { [attrName]: attrObj.default }, attrValueObj)
        const res = attrObj.renderHTML?.(obj)

        if (res) {
            htmlAttrList = mergeAttributes(htmlAttrList, res)
        }
    }

    return htmlAttrList as RetT
}
