import { nextTick } from 'vue'

export const useModalStore = defineStore('modal', {
    state: () => ({
        /**
         * Store the latest registered async content's status
         *
         * Any subsequent async content registration will replace the
         * current async content.
         */
        asyncStatus: {
            isResolved: true,
            key: Symbol(),
        },
        showModal: false,
        type: 'CRUD' as IModalType,
        closeByBackdropEnabled: false,
        content: <IModalStore>{},
        showModalFooter: false,
        scrollPosition: 0,
        cssWidth: 'max-w-60%',
        cssHeight: '',
    }),
    actions: {
        toggleShowModal() {
            this.showModal = true
            this.scrollPosition = window.scrollY;
        },
        async closeModal() {
            this.showModal = false
            await nextTick()
            window.scrollTo(0, this.scrollPosition)
            // Reset state to ensure a clean state for the next modal
            this.$reset()
        },
        setModal(type: IModalType, params: IModalStore) {
            // Override async content
            this.asyncStatus.key = Symbol()
            this.asyncStatus.isResolved = true

            this.type = type
            this.content = params
            this.closeByBackdropEnabled = !!params.closeByBackdropEnabled
            if (params.cssWidth) this.cssWidth = params.cssWidth
            if (params.cssHeight) this.cssHeight = params.cssHeight
            this.toggleShowModal()
        },
        /**
         * Used for modal content that requires `async` to be loaded
         *
         * This function will immediately show the modal with a loading icon,
         * while the data is being fetched, instead of waiting without any
         * indication until the modal pops up after the content has loaded.
         *
         * ### Example:
         * ```ts
         * const modalStore = useModalStore()
         *
         * modalStore.setModalAsync(
         *   'CRUD',
         *   async () => {
         *     return {
         *       title: 'Example',
         *       form: await composable.getForm(),
         *     }
         *   }
         * )
         * ```
         */
        async setModalAsync(type: IModalType, paramsFunc: () => Promise<IModalStore>) {
            this.setModal(type, {} as IModalStore) // Reset content

            // Register async content
            const key = Symbol()
            this.asyncStatus.isResolved = false
            this.asyncStatus.key = key

            const params = await paramsFunc()
            // Only apply `content` if it's the latest
            if (key === this.asyncStatus.key) {
                this.asyncStatus.isResolved = true

                this.content = params
                this.closeByBackdropEnabled = !!params.closeByBackdropEnabled
                if (params.cssWidth) this.cssWidth = params.cssWidth
                if (params.cssHeight) this.cssHeight = params.cssHeight
            }
        },
        setComponentModal(params: IModalStore) {
            this.setModal('component', params)
        },
        async setComponentModalAsync(paramsFunc: () => Promise<IModalStore>) {
            await this.setModalAsync('component', paramsFunc)
        },
    },
})
