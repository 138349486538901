import IwFormHtmlAction from '../../../utils/IwFormHtmlAction'
import Link from '@tiptap/extension-link'

/**
 * Parse and normalize URL by appending default URL protocol (E.g. 'HTTPS')
 */
export function normalizeLink(link: string | null | undefined): string | null {
    link = typeof link === 'string' ? link.trim() : null
    if (!link || link.startsWith('javascript:')) {
        return null
    }

    link = link.replaceAll('\'', '%27')
        .replaceAll('"', '%22')

    // Change URL relative path to absolute path
    // E.g. 'youtube.com' -> 'https://youtube.com'
    if (!/^(https?:\/\/|\/)/i.test(link)) {
        link = `https://${link}`
    }

    return link
}

export default Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            href: {
                parseHTML(element: HTMLLinkElement) {
                    const dataAttr = IwFormHtmlAction.getEditorParsedDataFromDataset(
                        'link.external-url',
                        element.dataset,
                    )

                    return element.href || dataAttr.linkExternalUrl
                },
                renderHTML: ({ href }) => {
                    href = normalizeLink(href)
                    if (href) {
                        return IwFormHtmlAction.generateDataAttrsFromDefaultList(
                            'link.external-url',
                            href,
                        )
                    }
                },
            },
        }
    },

    parseHTML() {
        return [
            ...(this.parent?.() ?? []),
            {
                tag: 'a',
            },
        ]
    },
})
