<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { Icon } from '@iconify/vue'

///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    css: {
        type: String
    },
    /**
     * Used for capturing the `disabled` property to logical OR with internal
     * disabled status on click
     *
     * The button's disabled status will mostly depend on the `props.disabled`
     * property, most of the time.
     *
     * The button will only be forcefully disabled when the `onClick` event is
     * fired (E.g. form submission), where the button will be disabled until the
     * callback function has finished executing.
     */
    disabled: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
    },
    isLoading: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
    },
    onClick: {
        type: Function as PropType<(...data: any[]) => any>,
    }
})

const isLoading = ref<boolean>(props.isLoading)
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

const btnCss = computed(() => {
    // `viBtnDisabled` style will only be activated when the button is disabled
    const css = props.css ?? 'viBtn viBtnDisabled'

    return css
})

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

async function onBtnClick() {
    if (!isLoading.value) {
        if (props.onClick) {
            isLoading.value = true
            await props.onClick()
            isLoading.value = false
        }
    }
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <button :class="btnCss"
            :disabled="props.disabled || isLoading"
            @click="onBtnClick">
        <span class="viBtnLabel flex justify-center items-center gap-4">
            <Icon v-if="icon"
                  :icon="icon"></Icon>
            {{ label }}
        </span>
        <span class="viBtnSpinner">
            <svg v-show="isLoading"
                 class="w-5 h-5 text-white animate-spin absolute left-1/2 -ml-2.5 transform"
                 fill="none"
                 viewBox="0 0 24 24">
                <circle class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor">
                </path>
            </svg>
        </span>
    </button>
</template>

<style scoped>
</style>
