<script setup lang='ts'>

///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { ref } from 'vue'
import { Icon } from '@iconify/vue'

///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    minHeight: {
        type: Number,
        default: window.innerHeight * 2, // Display btn on the 3th page
    },
    // The first element(browser window) is always null
    scrollableElement: {
        type: HTMLElement,
        default: null,
    }
})

let scrollWidth = 0
const btnRef = ref()
const showBackToTop = ref(false)
const isHovered = ref(false)

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
const isMouseOver = () => {
    isHovered.value = true
}

const isMouseOut = () => {
    isHovered.value = false
}

/**
 * Compare between element scroll-Y and configured minHeight
 */
const handleScroll = () => {
    const scrollY = props.scrollableElement ? props.scrollableElement.scrollTop : window.scrollY
    showBackToTop.value = scrollY > props.minHeight
    isHovered.value = false
}

/**
 * Scroll element to the top
 */
const scrollToTop = () => {
    if (props.scrollableElement) {
        props.scrollableElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    } else {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
}

/**
 * Determine the scrollbar width for any scrollable element.
 *
 * Component button would overlap with the scrollbar of the 2nd element,
 * thus scrollbar width is added to the 'right' style of the button class.
 *
 */
const calculateScrollbarWidth = () => {
    // Create a temporary div to measure scrollbar width
    let div = document.createElement('div')
    div.style.overflowY = 'scroll'
    div.style.width = '50px'
    div.style.height = '50px'

    document.body.appendChild(div)
    scrollWidth = div.offsetWidth - div.clientWidth
    document.body.removeChild(div)

    const backToTopElement = btnRef.value
    if (backToTopElement) {
        // Update the button style of the current element
        backToTopElement.style.right = `${scrollWidth}px`
    }
}

//////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

onMounted(() => {
    // Add event listener for elements
    if (props.scrollableElement !== null) {
        calculateScrollbarWidth() // Initial calculation before scroll
        window.addEventListener('resize', calculateScrollbarWidth)
        props.scrollableElement.addEventListener('scroll', handleScroll)
    } else {
        // Add event listener for browser window
        window.addEventListener('scroll', handleScroll)
    }
})

// Remove event listeners
onUnmounted(() => {
    if (props.scrollableElement !== null) {
        window.removeEventListener('resize', calculateScrollbarWidth)
        props.scrollableElement.removeEventListener('scroll', handleScroll)
    } else {
        window.removeEventListener('scroll', handleScroll)
    }
})

/////////////////////////////////////////////////////@  Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

</script>

<template>
    <div>
        <div @click.stop="scrollToTop()"
             @mouseover="isMouseOver"
             @mouseout="isMouseOut"
             ref="btnRef"
             class="back-to-top fixed cursor-pointer bottom-1/5 right-0 rounded-l-lg bg-primary-400 dark:bg-primary"
             :class="{ 'show': showBackToTop, 'hover': isHovered }">
            <Icon icon="material-symbols:keyboard-arrow-up"
                  class="text-5xl text-white" />
        </div>
    </div>
</template>

<style scoped>
.back-to-top {
    transition: opacity 0.5s, width 0.5s;
    opacity: 0;
    width: 0;
    overflow: hidden;
}

.back-to-top.show {
    opacity: 0.4;
    width: 1.5rem;
}

.back-to-top.show.hover {
    opacity: 1;
    width: 3rem;
}
</style>


