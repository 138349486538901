<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
    },
    initialValue: {
        type: Number,
        default: 0,
    },
    /**
     * Size between each interval
     */
    step: {
        type: Number,
        default: 1,
    },
    showSideValues: {
        type: Boolean,
        default: true,
    },
})

const emit = defineEmits(['change'])

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const rangeSliderInput = ref(props.initialValue)
const thumbValueRef = ref<HTMLSpanElement>()
const rangeRef = ref<HTMLInputElement>()

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
const formattedRangeSliderInput = computed(() => {
    let validInput = rangeSliderInput.value

    if (isNaN(validInput)) {
        validInput = 0
    }

    // Check if the step has decimals
    const stepDecimalPoints = props.step.toString().split('.')[1]?.length || 0
    const formattedValidInput = validInput.toFixed(stepDecimalPoints)
    rangeSliderInput.value = Number(formattedValidInput)

    emit('change', rangeSliderInput.value)

    return formattedValidInput
})

/**
 * Calculate the gradient position as a percentage of the total range
 */
const progressPercentage = computed(() => {
    const numerator = rangeSliderInput.value - props.min
    const denominator = props.max - props.min
    const gradientPosition = (numerator / denominator) * 100

    return gradientPosition
})

watch(rangeSliderInput, () => { updateThumbValuePosition(), clamp() }, { immediate: true })

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
function clamp() {
    if (props.min > rangeSliderInput.value) {
        rangeSliderInput.value = props.min
    } else if (props.max < rangeSliderInput.value) {
        rangeSliderInput.value = props.max
    }
}

/**
 * Constant update of the thumb position is required as:
 *
 * The bubble is too far from the left at the start and too far from the right
 * at the end. On range inputs, the thumb doesn’t hang off the left edge which
 * would make it’s center not being at the start, and same goes when it's
 * at the end.
 *
 */
function updateThumbValuePosition() {
    nextTick(() => {
        if (thumbValueRef.value) {
            thumbValueRef.value.style.left = `calc(${progressPercentage.value}% + (${8.5 - progressPercentage.value * 0.2}px))`
        }
    })
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div class="iwFormInputRangeSliderWrapper relative">
        <span v-if="props.showSideValues"
              class="iwFormInputRangeSliderSideDisplayValue">{{ props.min }}</span>
        <div class="relative w-full">
            <span ref="thumbValueRef"
                  class="iwFormInputRangeSliderThumbValue">{{ formattedRangeSliderInput }}</span>
            <input type="range"
                   ref="rangeRef"
                   v-model.number="rangeSliderInput"
                   :min="props.min"
                   :max="props.max"
                   :step="props.step"
                   class="iwFormInputRangeSliderTrack !bg-gradient-to-r from-primary to-gray-200 to-0%"
                   :style="`--un-gradient-from-position: ${progressPercentage}%`">
        </div>
        <span v-if="props.showSideValues"
              class="iwFormInputRangeSliderSideDisplayValue">{{ props.max }}</span>
    </div>
</template>

<style scoped>
</style>
