<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { directive as vTippy } from 'vue-tippy'
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    /** The current content in string for calculation */
    content: {
        type: String,
    },
    /** The maximum size allowed for the editor content */
    maxContentSizeInBytes: {
        type: Number,
    },
})
//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
/** Indicates the current size of the content */
const contentSize = ref<number>(0)

/** Indicates the defined limit for the content */
const contentLimit = computed(() => props.maxContentSizeInBytes)

/**
 * Indicates the remaining size of the content before reaching the limit
 *
 * - Positive values if the limit is not reached.
 * - Negative values if the limit has been surpassed.
 * - `Infinity` if the limit is not defined.
 */
const contentRemaining = computed(() =>
    contentLimit.value != null
        ? contentLimit.value - contentSize.value
        : Infinity
)

/** The rendered text that will be shown as tooltip */
const contentInfoTooltip = computed(() => {
    if (contentRemaining.value < Infinity) {
        const absNum = Math.abs(contentRemaining.value)
        const nounSuffix = absNum <= 1 ? '' : 's'
        const status = contentRemaining.value < 0 ? 'over limit' : 'remaining'

        return `${absNum} byte${nounSuffix} ${status}. (${contentSize.value} / ${contentLimit.value})`
    } else {
        const nounSuffix = contentSize.value <= 1 ? '' : 's'

        return `${contentSize.value} byte${nounSuffix} used.`
    }
})
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
watch(() => props.content, content => {
    contentSize.value = calculateTextUtf8Length(content ?? '')
}, { immediate: true })
//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
/**
 * Return the number of bytes occupied by a string of text converted to UTF-8
 */
function calculateTextUtf8Length(content: string): number {
    return new TextEncoder().encode(content).length
}

/**
 * Shorten bytes value by approximating to their decimal Metric (SI) prefix
 *
 * ### Example
 * ```
 * shortenBytesToStr(123) // returns: '123B'
 * shortenBytesToStr(12345) // returns: '12KB'
 * shortenBytesToStr(12345, 1) // returns: '12.3KB'
 *
 * shortenBytesToStr(-1_000_000) // returns: '-1MB'
 * ```
 */
function shortenBytesToStr(num: number, decimalPoints = 0): string {
    const kilo = 1e3
    const mega = 1e6
    const giga = 1e9
    const tera = 1e12
    const absNum = num < 0 ? -num : num

    const convert = (exp: number) => {
        return (num / exp)
            // Round number to the exact decimal points
            // E.g.
            // decimalPoints = 1,      1 --> '1.0'
            // decimalPoints = 2, 0.3333 --> '0.33'
            .toFixed(decimalPoints)
            // Remove all trailing zeroes from the decimal string
            // E.g. '1.00' -> '1'
            //      '0.33' -> '0.33'
            //      '1.10' -> '1.1'
            .replace(/\.?0+$/, '')
    }

    if (absNum >= tera) {
        return `${convert(tera)}TB`
    } else if (absNum >= giga) {
        return `${convert(giga)}GB`
    } else if (absNum >= mega) {
        return `${convert(mega)}MB`
    } else if (absNum >= kilo) {
        return `${convert(kilo)}kB`
    } else {
        return `${convert(1)}B`
    }
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div class="iwFormEditorLengthInfo"
         :class="{ 'error': contentRemaining < 0 }"
         v-tippy="contentInfoTooltip">
        {{ shortenBytesToStr(contentSize, 1) }}
    </div>
</template>
