import { type Attribute, type NodeConfig, Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import IwFormHtmlAction from '../../../utils/IwFormHtmlAction'
import EditorButtonNodeView from '../views/EditorButtonNodeView.vue'
import { normalizeLink } from './EditorLink'

/** Base attributes/options with default values */
interface EditorButtonOptions {
    /** Background style of the button */
    background: string
    /** Alignment of the button based on `TextAlign` extension */
    textAlign: string
    /** HTML class list to be used */
    class: string
    /** Colour of the text of the button */
    color: string
    /** Font size of the button text (with unit) */
    fontSize: string
    /** Height of the button (with unit) */
    height: string
    /** Border rounded radius (with unit) */
    roundedRadius: string
    /** Width of the button (with unit) */
    width: string

    /** Additional HTML attributes to be inserted */
    HTMLAttributes: Record<string, any>
}

/** Configurable attributes/options that may not have default values or are required */
export interface SetEditorButtonOptions extends Partial<Omit<
    EditorButtonOptions,
    'HTMLAttributes'
>> {
    /** Label for the button */
    text: string,
    /** Whether to include a class to enable ripple effect */
    rippleEnabled?: boolean,
    /** Link that the button will open on click */
    link?: string
}

export const defaultOptions = {
    background: '#37ca37',
    textAlign: 'left',
    class: '',
    color: '#ffffff',
    fontSize: '1rem',
    height: 'auto',
    roundedRadius: '0.25rem',
    width: 'auto',

    link: '',
    rippleEnabled: false,
    text: '',

    // Additional options to be included
    /** Default element display type */
    display: 'flex',
    /** Default padding top & bottom size if width is auto */
    paddingX: '3rem',
    /** Default padding left & right size if height is auto */
    paddingY: '1rem',
} satisfies Required<SetEditorButtonOptions & Record<string, any>>

/** Register available commands to Editor instance */
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        button: {
            /** Insert button into the Editor */
            setButton: (options: SetEditorButtonOptions) => ReturnType,
        }
    }
}

export const addAttributesFunc: NonNullable<NodeConfig['addAttributes']> = function () {
    return {
        background: {
            default: this.options.background,
            parseHTML: element => element.style.background || element.style.backgroundColor,
            renderHTML: ({ background }) => ({
                style: `background:${background}`,
            }),
        },
        class: {
            default: this.options.class,
            // Will automatically inserted as HTML attribute (`class='...'`),
            // when `renderHTML()` is not defined
        },
        color: {
            default: this.options.color,
            parseHTML: element => element.style.color,
            renderHTML: ({ color }) => ({
                style: `color:${color}`,
            }),
        },
        fontSize: {
            default: this.options.fontSize,
            parseHTML: element => element.style.fontSize,
            renderHTML: ({ fontSize }) => ({
                style: `font-size:${fontSize}`,
            }),
        },
        height: {
            default: this.options.height,
            parseHTML: element => element.style.height,
            renderHTML: ({ height }) => {
                const style = [`height:${height}`]
                if (height === 'auto') {
                    const padding = defaultOptions.paddingY
                    style.push(`padding-top:${padding}`)
                    style.push(`padding-bottom:${padding}`)
                }

                return {
                    style: style.join(';'),
                }
            },
        },
        link: {
            parseHTML: element => {
                const data = IwFormHtmlAction.getEditorParsedDataFromDataset(
                    'btn.external-url',
                    element.dataset,
                )
                return data.btnExternalUrl
            },
            renderHTML({ link }) {
                link = normalizeLink(link)
                if (!link) return undefined

                return IwFormHtmlAction.generateDataAttrsFromDefaultList(
                    'btn.external-url',
                    link,
                )
            }
        },
        rippleEnabled: {
            parseHTML: element => element.classList.contains('ripple'),
            renderHTML: ({ rippleEnabled }) => {
                if (rippleEnabled) {
                    return {
                        class: 'ripple',
                    }
                }
            },
        },
        roundedRadius: {
            default: this.options.roundedRadius,
            parseHTML: element => element.style.borderRadius,
            renderHTML: ({ roundedRadius }) => ({
                style: `border-radius:${roundedRadius}`,
            }),
        },
        text: {
            parseHTML: element => element.textContent,
            // Keep `text` as an attribute, but does not render any HTML
            // attribute
            renderHTML: () => ({})
        },
        textAlign: {
            default: this.options.textAlign,
            parseHTML: element => {
                if (element.style.textAlign) {
                    return element.style.textAlign
                }

                const marginLeft = element.style.marginLeft
                const marginRight = element.style.marginRight

                if (marginLeft === 'auto' && marginRight === 'auto') {
                    return 'center'
                } else if (marginLeft === 'auto') {
                    return 'right'
                } else {
                    return 'left'
                }
            },
            renderHTML: ({ textAlign }) => {
                const margin = {
                    left: '0',
                    right: '0',
                }

                switch (textAlign) {
                    case 'center':
                        margin.right = 'auto'
                    // `break` omitted intentionally
                    case 'right':
                        margin.left = 'auto'
                        break
                }

                return {
                    style: `margin-left:${margin.left};margin-right:${margin.right};`
                        // Override `text-align` value with `display` and `place-content`
                        // Use `place-items` to center other elements, such as images
                        + `display:${defaultOptions.display};place-content:center;place-items:center`,
                }
            },
        },
        width: {
            default: this.options.width,
            parseHTML: element => element.style.width,
            renderHTML: ({ width }) => {
                const style = [`width:${width}`]

                if (width === 'auto') {
                    const padding = defaultOptions.paddingX
                    style.push(`padding-left:${padding}`)
                    style.push(`padding-right:${padding}`)
                }

                return {
                    style: style.join(';'),
                }
            }
        },
    } satisfies Record<keyof SetEditorButtonOptions, Attribute | {}>
}

const editorButton = Node.create<EditorButtonOptions>({
    name: 'button',

    /** Allowed child nodes (by their groups) in this Node */
    content: 'inline*',
    draggable: true,
    /** Groups that this Node belongs to */
    group: 'block button',
    marks: '_',
    selectable: true,

    /** Define the default options to be used, and can be overridden */
    addOptions() {
        return Object.assign({ HTMLAttributes: {} }, defaultOptions)
    },

    /** Format custom attributes and render as HTML attributes */
    addAttributes: addAttributesFunc,

    addCommands() {
        return {
            setButton: options => ({ commands }) => {
                const brToken = '<br />'

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                    content: options.text
                        // Replace newlines with `<br/>`
                        .replaceAll(/(\r?\n)+/g, (match) => {
                            const num = match.match(/\n/g)!.length
                            return `\n${brToken}`.repeat(num) + '\n'
                        })
                        .split('\n')
                        .map(text => {
                            if (text === brToken) {
                                return {
                                    type: 'hardBreak',
                                }
                            } else {
                                return {
                                    type: 'text',
                                    text,
                                }
                            }
                        })
                })
            },
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(EditorButtonNodeView, {
            // NOTE: Fix draggable not working.
            // https://github.com/ueberdosis/tiptap/issues/3199#issuecomment-1438873110
            stopEvent: () => false,
        })
    },

    /** Parse any HTML elements that matches the returned list during init */
    parseHTML() {
        return [
            {
                tag: 'button',
            },
        ]
    },

    /** Render the element during init */
    renderHTML({ HTMLAttributes }) {
        return [
            'button',
            mergeAttributes(
                {
                    'data-button': '',
                    type: 'button',
                    role: 'button',
                },
                this.options.HTMLAttributes,
                {
                    class: this.options.class,
                },
                HTMLAttributes,
            ),
            0,
        ]
    },
})

export default editorButton
