import { default as forgotPasswordxR28OfNFmSMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/account/forgotPassword.vue?macro=true";
import { default as indexEhgMmReWvyMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/account/index.vue?macro=true";
import { default as _91id_93Xq1U8fs2LQMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/admins/[id].vue?macro=true";
import { default as index0lDQPH5CzPMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/admins/index.vue?macro=true";
import { default as indexAK8I9QojkGMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/audit-log/index.vue?macro=true";
import { default as indexqrFnqhnskLMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/commissions/index.vue?macro=true";
import { default as simulationpEXwBkTLJLMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/commissions/simulation.vue?macro=true";
import { default as _91id_932yZtZWP1X7Meta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/customers/[id].vue?macro=true";
import { default as indexQABmXuWXfLMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/customers/index.vue?macro=true";
import { default as dashboardEU2oukLd7KMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/dashboard.vue?macro=true";
import { default as deleteei5uyFpTUZMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/delete.vue?macro=true";
import { default as indexg19OAIJPXKMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-groups/[eid]/index.vue?macro=true";
import { default as indexoo0fnVugJnMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-groups/index.vue?macro=true";
import { default as index8I2cqpzCYdMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-logs/index.vue?macro=true";
import { default as buyersYMFp6XoAuxMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/[id]/buyers.vue?macro=true";
import { default as indexulWkpPwaFrMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/[id]/index.vue?macro=true";
import { default as indexb4dSJavvKfMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/index.vue?macro=true";
import { default as newjbE8bksDHTMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/new.vue?macro=true";
import { default as health1r9p7EVlzWMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/health.vue?macro=true";
import { default as indexEyklXlwW7TMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/index.vue?macro=true";
import { default as _91id_93237KVauAdYMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/[id].vue?macro=true";
import { default as index0P94JdorfTMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/index.vue?macro=true";
import { default as newXcv40ULavLMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/new.vue?macro=true";
import { default as loginOsQHlXrDkHMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/login.vue?macro=true";
import { default as indexQDdIDLVuZgMeta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/orders/index.vue?macro=true";
import { default as indexVUvCygS9q3Meta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/organization/index.vue?macro=true";
import { default as _91token_93ytOJDbbqz3Meta } from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/password-reset/[token].vue?macro=true";
export default [
  {
    name: forgotPasswordxR28OfNFmSMeta?.name ?? "account-forgotPassword",
    path: forgotPasswordxR28OfNFmSMeta?.path ?? "/account/forgotPassword",
    meta: forgotPasswordxR28OfNFmSMeta || {},
    alias: forgotPasswordxR28OfNFmSMeta?.alias || [],
    redirect: forgotPasswordxR28OfNFmSMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/account/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: indexEhgMmReWvyMeta?.name ?? "account",
    path: indexEhgMmReWvyMeta?.path ?? "/account",
    meta: indexEhgMmReWvyMeta || {},
    alias: indexEhgMmReWvyMeta?.alias || [],
    redirect: indexEhgMmReWvyMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Xq1U8fs2LQMeta?.name ?? "admins-id",
    path: _91id_93Xq1U8fs2LQMeta?.path ?? "/admins/:id()",
    meta: _91id_93Xq1U8fs2LQMeta || {},
    alias: _91id_93Xq1U8fs2LQMeta?.alias || [],
    redirect: _91id_93Xq1U8fs2LQMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/admins/[id].vue").then(m => m.default || m)
  },
  {
    name: index0lDQPH5CzPMeta?.name ?? "admins",
    path: index0lDQPH5CzPMeta?.path ?? "/admins",
    meta: index0lDQPH5CzPMeta || {},
    alias: index0lDQPH5CzPMeta?.alias || [],
    redirect: index0lDQPH5CzPMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/admins/index.vue").then(m => m.default || m)
  },
  {
    name: indexAK8I9QojkGMeta?.name ?? "audit-log",
    path: indexAK8I9QojkGMeta?.path ?? "/audit-log",
    meta: indexAK8I9QojkGMeta || {},
    alias: indexAK8I9QojkGMeta?.alias || [],
    redirect: indexAK8I9QojkGMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/audit-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexqrFnqhnskLMeta?.name ?? "commissions",
    path: indexqrFnqhnskLMeta?.path ?? "/commissions",
    meta: indexqrFnqhnskLMeta || {},
    alias: indexqrFnqhnskLMeta?.alias || [],
    redirect: indexqrFnqhnskLMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/commissions/index.vue").then(m => m.default || m)
  },
  {
    name: simulationpEXwBkTLJLMeta?.name ?? "commissions-simulation",
    path: simulationpEXwBkTLJLMeta?.path ?? "/commissions/simulation",
    meta: simulationpEXwBkTLJLMeta || {},
    alias: simulationpEXwBkTLJLMeta?.alias || [],
    redirect: simulationpEXwBkTLJLMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/commissions/simulation.vue").then(m => m.default || m)
  },
  {
    name: _91id_932yZtZWP1X7Meta?.name ?? "customers-id",
    path: _91id_932yZtZWP1X7Meta?.path ?? "/customers/:id()",
    meta: _91id_932yZtZWP1X7Meta || {},
    alias: _91id_932yZtZWP1X7Meta?.alias || [],
    redirect: _91id_932yZtZWP1X7Meta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQABmXuWXfLMeta?.name ?? "customers",
    path: indexQABmXuWXfLMeta?.path ?? "/customers",
    meta: indexQABmXuWXfLMeta || {},
    alias: indexQABmXuWXfLMeta?.alias || [],
    redirect: indexQABmXuWXfLMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardEU2oukLd7KMeta?.name ?? "dashboard",
    path: dashboardEU2oukLd7KMeta?.path ?? "/dashboard",
    meta: dashboardEU2oukLd7KMeta || {},
    alias: dashboardEU2oukLd7KMeta?.alias || [],
    redirect: dashboardEU2oukLd7KMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: deleteei5uyFpTUZMeta?.name ?? "delete",
    path: deleteei5uyFpTUZMeta?.path ?? "/delete",
    meta: deleteei5uyFpTUZMeta || {},
    alias: deleteei5uyFpTUZMeta?.alias || [],
    redirect: deleteei5uyFpTUZMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/delete.vue").then(m => m.default || m)
  },
  {
    name: indexg19OAIJPXKMeta?.name ?? "event-groups-eid",
    path: indexg19OAIJPXKMeta?.path ?? "/event-groups/:eid()",
    meta: indexg19OAIJPXKMeta || {},
    alias: indexg19OAIJPXKMeta?.alias || [],
    redirect: indexg19OAIJPXKMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-groups/[eid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoo0fnVugJnMeta?.name ?? "event-groups",
    path: indexoo0fnVugJnMeta?.path ?? "/event-groups",
    meta: indexoo0fnVugJnMeta || {},
    alias: indexoo0fnVugJnMeta?.alias || [],
    redirect: indexoo0fnVugJnMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-groups/index.vue").then(m => m.default || m)
  },
  {
    name: index8I2cqpzCYdMeta?.name ?? "event-logs",
    path: index8I2cqpzCYdMeta?.path ?? "/event-logs",
    meta: index8I2cqpzCYdMeta || {},
    alias: index8I2cqpzCYdMeta?.alias || [],
    redirect: index8I2cqpzCYdMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/event-logs/index.vue").then(m => m.default || m)
  },
  {
    name: buyersYMFp6XoAuxMeta?.name ?? "events-id-buyers",
    path: buyersYMFp6XoAuxMeta?.path ?? "/events/:id()/buyers",
    meta: buyersYMFp6XoAuxMeta || {},
    alias: buyersYMFp6XoAuxMeta?.alias || [],
    redirect: buyersYMFp6XoAuxMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/[id]/buyers.vue").then(m => m.default || m)
  },
  {
    name: indexulWkpPwaFrMeta?.name ?? "events-id",
    path: indexulWkpPwaFrMeta?.path ?? "/events/:id()",
    meta: indexulWkpPwaFrMeta || {},
    alias: indexulWkpPwaFrMeta?.alias || [],
    redirect: indexulWkpPwaFrMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexb4dSJavvKfMeta?.name ?? "events",
    path: indexb4dSJavvKfMeta?.path ?? "/events",
    meta: indexb4dSJavvKfMeta || {},
    alias: indexb4dSJavvKfMeta?.alias || [],
    redirect: indexb4dSJavvKfMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: newjbE8bksDHTMeta?.name ?? "events-new",
    path: newjbE8bksDHTMeta?.path ?? "/events/new",
    meta: newjbE8bksDHTMeta || {},
    alias: newjbE8bksDHTMeta?.alias || [],
    redirect: newjbE8bksDHTMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: health1r9p7EVlzWMeta?.name ?? "health",
    path: health1r9p7EVlzWMeta?.path ?? "/health",
    meta: health1r9p7EVlzWMeta || {},
    alias: health1r9p7EVlzWMeta?.alias || [],
    redirect: health1r9p7EVlzWMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/health.vue").then(m => m.default || m)
  },
  {
    name: indexEyklXlwW7TMeta?.name ?? "index",
    path: indexEyklXlwW7TMeta?.path ?? "/",
    meta: indexEyklXlwW7TMeta || {},
    alias: indexEyklXlwW7TMeta?.alias || [],
    redirect: indexEyklXlwW7TMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93237KVauAdYMeta?.name ?? "leads-id",
    path: _91id_93237KVauAdYMeta?.path ?? "/leads/:id()",
    meta: _91id_93237KVauAdYMeta || {},
    alias: _91id_93237KVauAdYMeta?.alias || [],
    redirect: _91id_93237KVauAdYMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/[id].vue").then(m => m.default || m)
  },
  {
    name: index0P94JdorfTMeta?.name ?? "leads",
    path: index0P94JdorfTMeta?.path ?? "/leads",
    meta: index0P94JdorfTMeta || {},
    alias: index0P94JdorfTMeta?.alias || [],
    redirect: index0P94JdorfTMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/index.vue").then(m => m.default || m)
  },
  {
    name: newXcv40ULavLMeta?.name ?? "leads-new",
    path: newXcv40ULavLMeta?.path ?? "/leads/new",
    meta: newXcv40ULavLMeta || {},
    alias: newXcv40ULavLMeta?.alias || [],
    redirect: newXcv40ULavLMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/leads/new.vue").then(m => m.default || m)
  },
  {
    name: loginOsQHlXrDkHMeta?.name ?? "login",
    path: loginOsQHlXrDkHMeta?.path ?? "/login",
    meta: loginOsQHlXrDkHMeta || {},
    alias: loginOsQHlXrDkHMeta?.alias || [],
    redirect: loginOsQHlXrDkHMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexQDdIDLVuZgMeta?.name ?? "orders",
    path: indexQDdIDLVuZgMeta?.path ?? "/orders",
    meta: indexQDdIDLVuZgMeta || {},
    alias: indexQDdIDLVuZgMeta?.alias || [],
    redirect: indexQDdIDLVuZgMeta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUvCygS9q3Meta?.name ?? "organization",
    path: indexVUvCygS9q3Meta?.path ?? "/organization",
    meta: indexVUvCygS9q3Meta || {},
    alias: indexVUvCygS9q3Meta?.alias || [],
    redirect: indexVUvCygS9q3Meta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/organization/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ytOJDbbqz3Meta?.name ?? "password-reset-token",
    path: _91token_93ytOJDbbqz3Meta?.path ?? "/password-reset/:token()",
    meta: _91token_93ytOJDbbqz3Meta || {},
    alias: _91token_93ytOJDbbqz3Meta?.alias || [],
    redirect: _91token_93ytOJDbbqz3Meta?.redirect || undefined,
    component: () => import("/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/pages/password-reset/[token].vue").then(m => m.default || m)
  }
]