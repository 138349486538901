<script setup lang='ts'>
///////////////////////////////////////////////// Import, Types & meta
//////////////////////////////////////////////////////////////////////
function getDate() {
    let today = new Date();
    let year = today.getFullYear();

    return year
}
</script>

<template>
    <div class="text-center sm:text-center md:text-right lg:text-right xl:text-right mt-10 pb-5 w-full pr-3 text-sm">
        Copyright © 2018-{{ getDate() }} <a target="_blank"
           class="text-blue-500"
           href="https://www.vilor.com/">Vilor</a>. All rights reserved.
        <span class="text-xs text-slate-500"> Reg. No. 201501041587 (1166908-K) </span>
    </div>
</template>
<style scoped>
</style>
