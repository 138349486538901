<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import IwFormHtmlAction from '../../utils/IwFormHtmlAction'
import { Icon } from "@iconify/vue"
import { directive as vTippy } from 'vue-tippy'
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    /** The raw HTML code to be previewed */
    content: {
        type: String,
        required: true,
    }
})

enum DeviceSize {
    Auto,
    Small,
    Medium,
    Large,
    ExtraLarge,
}
//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const deviceSizes = {
    [DeviceSize.Auto]: {
        icon: 'fluent:arrow-autofit-width-20-filled',
        name: 'Auto (Current Device Size)',
        className: '',
    },
    [DeviceSize.Small]: {
        icon: 'carbon:tablet',
        name: 'Small Devices',
        className: 'iwFormEditorPreviewExtraSmall',
    },
    [DeviceSize.Large]: {
        icon: 'radix-icons:laptop',
        name: 'Large Devices',
        className: 'iwFormEditorPreviewLarge',
    },
    [DeviceSize.ExtraLarge]: {
        icon: 'ph:monitor',
        name: 'Extra Large Devices',
        className: 'iwFormEditorPreviewExtraLarge',
    },
}

const showPreview = ref(false)
const selectedSize = ref(DeviceSize.Auto)

/** Ref to the container that contains the page */
const previewRef = ref<HTMLDivElement>()
/** Ref to the container that renders the editor content */
const contentRef = ref<HTMLElement>()

const htmlAction = new IwFormHtmlAction()
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
/**
 * Close the preview modal
 */
function close() {
    showPreview.value = false

    document.body.style.overflow = 'auto'

    htmlAction.uninstallAction()
}

/**
 * Return whether the preview modal is shown or hidden
 */
function isVisible() {
    return showPreview.value
}

/**
 * Open the preview modal
 */
function open() {
    showPreview.value = true

    document.body.style.overflow = 'hidden'

    nextTick().then(() => {
        // Focus on the modal for making 'Esc' key closing work
        if (previewRef.value) {
            previewRef.value.focus()
        }
        // Install 'actions' onto elements
        if (contentRef.value) {
            htmlAction.installAction(contentRef.value.querySelectorAll('*'))
        }
    })
}

/**
 * Toggle to show or hide the preview modal
 */
function toggle() {
    if (showPreview.value) {
        close()
    } else {
        open()
    }
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
defineExpose({
    close,
    isVisible,
    open,
    toggle,
})
</script>

<template>
    <div v-if="showPreview"
         ref="previewRef"
         class="overflow-y-hidden"
         tabindex="-1"
         @keyup.esc.exact.self="close">
        <Icon class="iwFormEditorPreviewCloseBtnIcon"
              icon="basil:cross-outline"
              role="button"
              v-tippy="'Close preview'"
              @click="close" />
        <div class="iwFormEditorPreviewSizingMenu">
            <Icon v-for="(device, type) of deviceSizes"
                  class="iwFormEditorPreviewSizingBtnIcon"
                  :class="{ 'active': selectedSize === type }"
                  :icon="device.icon"
                  v-tippy="device.name"
                  @click="selectedSize = type" />
        </div>
        <section class="iwFormEditorPreview"
                 :class="[
                     $attrs.class ? $attrs.class : 'iwFormEditorPreviewDefaultStyle',
                     deviceSizes[selectedSize].className,
                 ]"
                 ref="contentRef"
                 v-html="props.content">
        </section>
    </div>
</template>
