<!-- Reference: https://stackoverflow.com/a/72512368 -->
<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

import { generateHtmlAttributes } from './helper'
import { type SetEditorButtonOptions, addAttributesFunc } from '../extensions/EditorButton'
import { normalizeLink } from '../extensions/EditorLink'
import { Icon } from '@iconify/vue'
import { type Attribute, nodeViewProps, NodeViewContent, NodeViewWrapper } from '@tiptap/vue-3'
import { Tippy } from 'vue-tippy'

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

const props = defineProps(nodeViewProps)

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/** Button's raw config (Not yet converted to HTML attributes) */
const attrs = toRef(() => props.node.attrs as SetEditorButtonOptions)

/** Block `onclick` HTML attribute */
const attrsToBeIgnored: Array<keyof SetEditorButtonOptions> = ['link']

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

/** Attributes object list to convert attrs to HTML attributes */
const attrConvertList = computed(() => {
    return addAttributesFunc.apply({
        name: props.extension.name,
        options: props.extension.options,
        parent: undefined,
        storage: undefined,
    }) as Record<keyof SetEditorButtonOptions, Attribute>
})

/** Converted HTML attributes with some attributes removed */
const htmlAttributes = computed(() => {
    return generateHtmlAttributes(
        attrConvertList.value,
        attrs.value,
        attrsToBeIgnored,
    )
})

/** URL embedded in the button */
const btnUrl = computed(() => normalizeLink(attrs.value.link))

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <NodeViewWrapper>
        <button class="relative"
                data-drag-handle=""
                draggable="true"
                type="button"
                v-bind="htmlAttributes"
                @change="(el) => props.updateAttributes({
                    text: (el.target as HTMLElement).textContent
                })">
            <Tippy class="iwFormEditorBtnViewLinkTippy"
                   :interactive="true"
                   trigger="click mouseenter">
                <template #default>
                    <Icon v-if="btnUrl"
                          class="iwFormEditorBtnViewLinkIcon"
                          icon="akar-icons:link-chain" />
                </template>

                <template #content>
                    <b>URL:</b>&nbsp;
                    <span class="text-nowrap">{{ btnUrl }}</span>
                </template>
            </Tippy>
            <NodeViewContent class="cursor-text" />
        </button>
    </NodeViewWrapper>
</template>
