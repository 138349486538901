<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { setLabel, setRequired } from '../composables/useIwForm';
import { Icon } from '@iconify/vue';
import { computed } from 'vue'
import { directive as tippyDirective } from 'vue-tippy'

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    id: {
        type: String,
    },
    item: {
        type: Object as PropType<IwFormInputCore>,
        required: true,
    },
    myFormData: {
        type: Object,
        required: true
    }
})

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const vTippy = tippyDirective

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

/** Process the label properties to return an object or `null` */
const labelProps = computed(() =>
    props.item.label && typeof props.item.label !== 'string'
        ? props.item.label
        : null
)

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div class="iwFormInputLabelInlineWrapper">
        <component :is="item.hasLabelForAttr || null == item.hasLabelForAttr ? 'label' : 'span'"
                   class="iwFormInputLabelInline"
                   :for="props.id ?? null">
            <span :class="labelProps?.textClass">
                {{ setLabel(item) }}
            </span>
            <Icon v-if="labelProps?.icon"
                  :class="[
                      labelProps.iconClass,
                      { '-order-1': labelProps?.iconPosition === 'start' },
                  ]"
                  :icon="labelProps.icon" />
        </component>

        <span v-if="setRequired(myFormData, (item as IwFormInputCore))"
              class="iwFormInputLabelRequired"> *</span>

        <span v-if="(item as IwFormInputCore).tooltip">
            <Icon icon="material-symbols:contact-support-outline-rounded"
                  class="iwFormInputLabelInlineIcon"
                  v-tippy="(item as IwFormInputCore).tooltip" />
        </span>
    </div>
</template>

<style scoped>
</style>
