<script setup lang='ts'>
import { ref } from 'vue';

///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////// @ Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    /**
     * Choices for each radio button
     *
     * Example:
     * [{ label: 'Option 1', value: 'Yes' },
     * { label: 'Option 2', value: 2 },
     * { label: 'Option 3', value: true }]
     */
    choice: {
        type: Array as PropType<IwFormInputRadio['choice']>,
        required: true,
    },
    /**
     * Increase this to display a horizontal radio
     */
    columnCount: {
        type: Number,
        default: 1,
    },
    disabled: {
        type: Boolean,
    },
    /**
     * Used for preselecting a radio button using value of choice
     *
     * Example: 'Yes', would select the Option 1 using the above examples
     */
    value: {
        type: [Boolean, Number, String],
    }
})

const emit = defineEmits<{
    (e: 'change', val: boolean | number | string): void,
}>()

////////////////////////////////////////////////////////// @ Variables
//////////////////////////////////////////////////////////////////////
const radioGroupName = ref('choices-' +
    Math.random().toString(36).slice(2, 11))
const radioRef = ref()

////////////////////////////////////////////////////////// @ Functions
//////////////////////////////////////////////////////////////////////
function onChange(choiceValue: boolean | number | string) {
    emit('change', choiceValue)
}

////////////////////////////////////////////////////////// @ Lifecycle
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div class="iwFormInputRadioWrapper"
         tabindex="0"
         :style="columnCount >= 1
             ? `grid-template-columns: repeat(${columnCount}, minmax(0, 1fr))`
             : `grid-template-columns: repeat(1, minmax(0, 1fr))`">
        <template v-for="(choice, index) in props.choice"
                  :key="index">
            <label class="iwFormInputRadioOptions"
                   :class="{ 'iwFormInputRadioDisabled': disabled }">
                <input type="radio"
                       ref="radioRef"
                       :disabled="disabled"
                       :checked="props.value === choice.value"
                       :id="'choice-' + index"
                       :name="radioGroupName"
                       @change="onChange(choice.value)" />
                <span :class="disabled ? 'iwFormInputRadioDisabled' : 'cursor-pointer'"
                      :for="'choice-' + index">{{ choice.label }}</span>
            </label>
        </template>
    </div>
</template>
