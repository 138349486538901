<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import EditorContentSizeInfo from './addons/EditorContentSizeInfo.vue';
import EditorUsageGuide from './addons/EditorUsageGuide.vue';

import IwFormRule from '../../utils/IwFormRule';
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
const emit = defineEmits(['change'])

const props = defineProps({
    config: {
        type: Object as PropType<IwFormEditorTextAreaConfig>,
        required: true,
    },
    formInput: {
        type: Object as PropType<IwFormInputEditorTextArea>,
        required: true,
    },
    id: {
        type: String,
    }
})
//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const defaultId = ((new Date()).getTime() + Math.floor((Math.random() * 10000))).toString()
const defaultConfig: IwFormEditorTextAreaConfig = {
    placeholder: 'Write something ...',
    htmlPlainTextSlots: [defaultId]
}
const config: IwFormEditorTextAreaConfig = Object.assign({}, defaultConfig, props.config)

/** Used for defining what textarea to be inserted */
const textAreaRefs = ref<Array<HTMLTextAreaElement>>([])

/** Hint for content size counter to update */
const contentCounterUpdateKey = ref(new Date().getTime())

/** Store unparsed content */
let unparsedContent = ''
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
/**
 * Process all the textareas and return with each wrapped with their tag
 *
 * ### Example:
 * ```
 * getTextAreasContent()
 * // returns: '<title>Foo</title><comment>Bar</comment>'
 * ```
 */
function getTextAreasContent(keepUnparsed = true): string {
    let content = textAreaRefs.value
        .map(textarea => {
            const tagName = textarea.dataset.tagName
            const content = textarea.value.trim()

            return content ? `<${tagName}>${content}</${tagName}>` : ''
        })
        .join('')

    if (keepUnparsed) {
        content += unparsedContent
    }

    return content
}

/**
 * Resize textarea element to match their content size
 */
function resizeTextArea(textAreaElem?: HTMLTextAreaElement) {
    if (textAreaElem) {
        // Get current height with padding and border, remove trailing 'px'
        const curHeight = +textAreaElem.style.height.slice(0, -2);

        // Save marginBottom value to reuse later
        const marginBottom = +window.getComputedStyle(textAreaElem)
            .marginBottom
            .slice(0, -2)

        // Add margin with previous height to prevent jump due to height change.
        // 'Jump' Visual:
        // (Happens if the textarea is near the bottom of page body)
        // (Imagine the page view height is 3 rows)
        //
        // |b   |  <-- Current top of page
        // |c   |
        // ------
        // ######  <= Page bottom
        //
        // ====================
        //
        //         <-- Current top of page
        // ------      after resizing to 0 height
        // ------
        // ######  <= Page Bottom
        //
        // ====================
        //
        //         <-- Current top of page
        // ------      after resetting height value to previous value
        // |a   |
        textAreaElem.style.marginBottom = marginBottom + curHeight + 'px'

        // Set height to 0 for recalculating scrollHeight value
        // (scrollHeight value doesn't change if number of lines decreases)
        textAreaElem.style.height = '0'

        // Add 2 pixels to remove scroll bar after extend
        textAreaElem.style.height = textAreaElem.scrollHeight + 2 + 'px'
        // Reset margin bottom value
        textAreaElem.style.marginBottom = marginBottom + 'px'
    }
}

/**
 * Handle plain text, textarea input update
 *
 * @param event Change or Input event
 */
function onTextAreaUpdate(event?: Event, emitUpdate: boolean = true) {
    // NOTE: commented out for possible future use
    // if (event) {
    //     resizeTextArea(event.target as HTMLTextAreaElement)
    // }

    contentCounterUpdateKey.value = new Date().getTime()

    if (emitUpdate) {
        emit('change', getTextAreasContent())
    }
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////
onMounted(() => {
    if (config.content) {
        if (config.isExtractByTopLevelTag) {
            // Get all top-level elements from the imported content data
            const topLevelElemList = Object.fromEntries(
                Array.from(
                    (new window.DOMParser())
                        .parseFromString(config.content, 'text/html')
                        .body
                        .children
                )
                    .map(el => [el.tagName.toLowerCase(), el])
            )

            // Extract HTML tags' content and insert into textarea
            textAreaRefs.value.forEach(textarea => {
                const tagName = textarea.dataset.tagName ?? ''
                const content = topLevelElemList[tagName]?.textContent ?? ''

                // Remove tag from unparsed list
                delete topLevelElemList[tagName]

                textarea.textContent = content.trim()
            })

            // Store unparsed content
            unparsedContent = Object.entries(topLevelElemList)
                .map(([tag, el]) => `<${tag}>${el.innerHTML}</${tag}>`)
                .join('')

            if (unparsedContent) {
                console.warn(
                    '[EditorTextArea] Unparsed content found:\n"'
                    + unparsedContent + '"'
                )
            }
        } else {
            textAreaRefs.value[0].textContent = config.content
        }

        onTextAreaUpdate()
    }

    // Apply content size limiter on save
    if (config.maxContentSizeInBytes != null) {
        const lengthRule = IwFormRule.maxSizeInBytes({
            max: config.maxContentSizeInBytes,
            errMsg: 'The size of content exceeds the size limit'
        })

        if (props.formInput.rules) {
            props.formInput.rules.push(lengthRule)
        } else {
            props.formInput.rules = [lengthRule]
        }
    }
})
//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <EditorUsageGuide :usageGuide="config.usageGuide" />

    <div class="iwFormEditor">
        <template v-for="tagName in config.htmlPlainTextSlots"
                  :key="tagName">
            <label :for="tagName + id"
                   class="iwFormInputLabelInline iwFormEditorTextAreaLabel">
                {{ tagName != defaultId ? tagName + ':' : null }}
            </label>
            <textarea class="iwFormEditorContent iwFormEditorTextArea"
                      :data-tag-name="tagName"
                      :placeholder="config.placeholder"
                      :id="tagName + id"
                      ref="textAreaRefs"
                      rows="20"
                      @input="onTextAreaUpdate($event)"></textarea>
        </template>

        <EditorContentSizeInfo :content="getTextAreasContent()"
                               :key="contentCounterUpdateKey"
                               :maxContentSizeInBytes="config.maxContentSizeInBytes" />
    </div>
</template>
