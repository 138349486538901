export const countries = {
    'CHN': {
        countryCode: '86',
        flag: '🇨🇳',
        name: 'China',
    },
    'HKG': {
        countryCode: '852',
        flag: '🇭🇰',
        name: 'Hong Kong',
    },
    'IDN': {
        countryCode: '62',
        flag: '🇮🇩',
        name: 'Indonesia',
    },
    'MYS': {
        countryCode: '60',
        flag: '🇲🇾',
        name: 'Malaysia',
    },
    'SGP': {
        countryCode: '65',
        flag: '🇸🇬',
        name: 'Singapore',
    },
    'TWN': {
        countryCode: '886',
        flag: '🇹🇼',
        name: 'Taiwan',
    },
} as const

export const availableCountryIsoCode = Object.keys(countries)

export default countries
