<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

import { type SetEditorButtonOptions, defaultOptions } from '../extensions/EditorButton'
import { IwFormColor } from '../../../utils/IwFormColor'
import { Icon } from '@iconify/vue'
import { directive as vTippy } from 'vue-tippy'

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

const props = defineProps({
    btnClassWhenNoUrl: {
        type: String,
        default: '',
    },
    btnClassWhenHasUrl: {
        type: String,
        default: '',
    },
})

const emit = defineEmits<{
    (e: 'edit', opt: SetEditorButtonOptions): void,
    (e: 'insert', opt: SetEditorButtonOptions): void,
}>()

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/**
 * List of selectable width percentages
 */
const widthPercentageList = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

/** The visibility of the dropdown */
const isVisible = ref(false)
/** Indicate if the current component is currently editing an existing button */
const isEditing = ref(false)
/**
 * Indicate if the URL input is enabled for insertion/editing
 */
const isUrlInputEnabled = ref(false)

const config = reactive({
    class: '',
    text: '',
    background: defaultOptions.background,
    color: defaultOptions.color,
    link: '',
    width: defaultOptions.width,
})

const identifier = (new Date()).getTime() + Math.random() * 10000

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

const isBtnTextInputEnabled = computed(() =>
    // Disable button text edit, since text node is not modified through attribute
    !isEditing.value
)

const isInsertBtnEnabled = computed(() =>
    (
        (config.text && config.text.length != 0)
        || isEditing.value
    )
    && (
        // If URL is enabled, URL input must have data
        !isUrlInputEnabled.value
        || (
            config.link
            && config.link.trim().length != 0
        )
    )
)

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

function getId(name: string) {
    return `${identifier}-${name}`
}

/**
 * Used externally to load previously-inserted custom button for editing
 *
 * See also {@link unload} to set this component to 'Insert' mode instead of
 * 'Edit' mode.
 *
 * @param attrs Attributes of the existing button
 */
function load(attrs: SetEditorButtonOptions) {
    // Convert to hex colours for `<input>` to work
    if (attrs.background) {
        attrs.background = IwFormColor.initFromString(attrs.background).toHex()
    }
    if (attrs.color) {
        attrs.color = IwFormColor.initFromString(attrs.color).toHex()
    }
    if (attrs.link) {
        isUrlInputEnabled.value = true
    }

    for (const key in config) {
        if (attrs[key] != null) {
            config[key] = attrs[key]
        }
    }

    isEditing.value = true
}

/**
 * Hide button configuration window
 */
function hide() {
    isVisible.value = false
}

/**
 * Reset configurable options to initial state
 */
function resetConfig() {
    for (const key in config) {
        if (defaultOptions[key] != null) {
            config[key] = defaultOptions[key]
        }
    }

    isUrlInputEnabled.value = false
}

/**
 * Show button configuration window
 */
function show() {
    isVisible.value = true
}

/**
 * Toggle to show or hide button configuration window
 */
function toggle() {
    if (isVisible.value) {
        hide()
    } else {
        show()
    }
}

/**
 * Toggle to show or hide URL input
 */
function toggleUrlInput() {
    isUrlInputEnabled.value = !isUrlInputEnabled.value
}

/**
 * Used externally to reset this component to 'Insert' mode
 *
 * See also {@link load} to load existing button for editing.
 */
function unload() {
    isEditing.value = false
    resetConfig()
}

/**
 * Event handler for insert/edit button click
 */
function onInsert() {
    if (isUrlInputEnabled.value) {
        config.class = props.btnClassWhenHasUrl
    } else {
        // Remove stored URL (URL is not cleared when input gets hidden)
        config.link = ''
        config.class = props.btnClassWhenNoUrl
    }

    if (isEditing.value) {
        emit('edit', config)
    } else {
        emit('insert', config)
        resetConfig()
    }
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

defineExpose({
    hide,
    load,
    show,
    toggle,
    unload,
})
</script>

<template>
    <Transition name="fade">
        <div v-show="isVisible"
             class="iwFormEditorDropdown"
             title=""> <!-- Stop title inheritance -->
            <label class="iwFormEditorDropdownLabel required"
                   :for="getId('text')">Text</label>
            <div>
                <textarea type="text"
                          autocomplete="off"
                          class="iwFormEditorDropdownInputText"
                          name="text"
                          :id="getId('text')"
                          placeholder="Button text..."
                          size="20"
                          v-model="config.text"
                          :disabled="!isBtnTextInputEnabled"></textarea>
            </div>

            <div class="iwFormEditorDropdownMarginInput"
                 v-show="true || isUrlInputEnabled">
                <label class="iwFormEditorDropdownLabel"
                       :class="isUrlInputEnabled ? 'required' : 'disabled'"
                       :for="getId('url')">URL</label>

                <input class="iwFormEditorDropdownInputText iwFormEditorDropdownInputUrl"
                       :disabled="!isUrlInputEnabled"
                       :id="getId('url')"
                       name="url"
                       placeholder="www.example.com"
                       type="url"
                       v-model="config.link" />
            </div>

            <section class="iwFormEditorDropdownConfigSection iwFormEditorDropdownMarginSection">
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('background')">
                        Background
                    </label>
                    <input class="iwFormEditorDropdownConfigInputColor"
                           type="color"
                           name="background"
                           :id="getId('background')"
                           v-model="config.background" />
                </span>
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('color')">
                        Color
                    </label>
                    <input class="iwFormEditorDropdownConfigInputColor"
                           type="color"
                           name="color"
                           :id="getId('color')"
                           v-model="config.color" />
                </span>
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('widthPercent')">
                        <Icon class="iwFormEditorDropdownConfigInputLabelIcon"
                              icon="material-symbols:help-outline-rounded"
                              v-tippy="'Based on the width of the article'" />
                        Width%
                    </label>
                    <select class="iwFormEditorDropdownConfigInputSelect"
                            name="widthPercent"
                            :id="getId('widthPercent')"
                            v-model="config.width">
                        <option value="auto">
                            Auto
                        </option>
                        <option v-for="(value, idx) in widthPercentageList"
                                :key="idx"
                                :value="`${value}%`">
                            {{ value }}%
                        </option>
                    </select>
                </span>
                <span>
                    <span class="iwFormEditorDropdownConfigInputLabel">
                        <Icon class="iwFormEditorDropdownConfigInputLabelIcon"
                              icon="material-symbols:help-outline-rounded"
                              v-tippy="isUrlInputEnabled
                                  ? 'Open URL in a new tab, when the button is clicked'
                                  : 'Open registration form in landing page, when the button is clicked'" />
                        <span @click="toggleUrlInput()">
                            <span class="iwFormEditorDropdownConfigInputLabelSwitch"
                                  :class="{ 'activated': !isUrlInputEnabled }">
                                Form
                            </span>/
                            <span class="iwFormEditorDropdownConfigInputLabelSwitch"
                                  :class="{ 'activated': isUrlInputEnabled }">
                                URL
                            </span>
                        </span>
                    </span>
                    <div class="iwFormEditorDropdownConfigInputSwitch"
                         :class="isUrlInputEnabled ? 'activated' : ''"
                         @click="toggleUrlInput()">
                    </div>
                </span>
            </section>

            <input type="button"
                   class="iwFormEditorDropdownInsertBtn iwFormEditorDropdownMarginSection"
                   :disabled="!isInsertBtnEnabled"
                   :value="isEditing ? 'Edit' : 'Insert'"
                   @mousedown.prevent="onInsert()">
        </div>
    </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .25s
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0
}
</style>
