<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

import { availableCountryIsoCode, countries } from '../utils/IwFormCountry'
import useInputPhoneUtils from '../composables/useInputPhoneUtils'

type PhoneData = {
    countryCode: IwFormPhoneCountryCodeWithUnknown
    phoneNumber: string
}

defineOptions({
    // Disallow HTML attributes fallthrough to top-level element, instead
    // intercept and insert into internal input element.
    inheritAttrs: false,
})

const phoneUtils = useInputPhoneUtils()

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

const emit = defineEmits<{
    (e: 'blur', ev: Event): void,
    (e: 'change', val: PhoneData): void,
    (e: 'focus', ev: Event): void,
    (e: 'init', val: PhoneData): void,
    (e: 'input', val: PhoneData): void,
}>()

const props = defineProps({
    /**
     * The list of country code (in ISO alpha-3 format) that will be read and
     * displayed
     */
    countryCodeList: {
        type: Array as PropType<Array<IwFormCountryISOCode>>,
        default: availableCountryIsoCode,
    },
    /**
     * Initial phone country code value to be used
     */
    initCountryCodeVal: {
        type: [String, null] as PropType<string | null>,
    },
    /**
     * Initial phone number value to be used
     */
    initPhoneNumberVal: {
        type: [String, null] as PropType<string | null>,
    },
    /**
     * Whether or not to show or hide the country code selection
     */
    showCountryCode: {
        type: Boolean,
        default: false,
    },
})

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/** Reflect the current phone number that the internal input element contains */
const phoneNumberData = ref('')
const phoneNumberInputRef = ref<HTMLInputElement>()

/** Reflect the current country code selected */
const selectedCountryCode = ref<IwFormPhoneCountryCodeWithUnknown>('')

/** Store API country code that was not found in local list */
const unknownCountryCodeFromAPI = ref<IwFormPhoneCountryCodeWithUnknown | null>(null)

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

function init() {
    // Set initial values
    const parsedInitData = phoneUtils.parseInitData(
        props.initCountryCodeVal as IwFormPhoneCountryCodeWithUnknown,
        props.initPhoneNumberVal
    )
    selectedCountryCode.value = parsedInitData.countryCode
    phoneNumberData.value = parsedInitData.phoneNumber

    if (selectedCountryCode.value !== phoneUtils.notApplicableOptionVal) {
        // Store unknown country code from API
        const isCountryCodeFound = phoneUtils.findCountryCode(
            props.countryCodeList,
            selectedCountryCode.value
        )
        if (!isCountryCodeFound) {
            unknownCountryCodeFromAPI.value = selectedCountryCode.value
        }
    } else if (props.showCountryCode) {
        // Set default country code if country code is 'N/A'
        selectedCountryCode.value = phoneUtils.getDefaultCountryCode(props.countryCodeList)
    }

    // Emit initialisation change
    emit('init', {
        countryCode: selectedCountryCode.value,
        phoneNumber: phoneNumberData.value,
    })
}

/**
 * Clear internal phone data value
 */
function clear() {
    phoneNumberData.value = ''
    onChange()
}

/**
 * Event handler for change in phone number form input
 */
function onChange() {
    let phoneNumber = phoneUtils.normalisePhoneNumber(phoneNumberData.value, selectedCountryCode.value)

    // Re-insert formatted phone number back to the input element
    phoneNumberData.value = phoneNumber

    emit('change', {
        countryCode: selectedCountryCode.value,
        phoneNumber,
    })
}

/**
 * Event handler for every keystroke of phone number form input
 */
function onInput() {
    // NOTE: Unlike `onChange`, phone number is not processed on each keystroke
    emit('input', {
        countryCode: selectedCountryCode.value,
        phoneNumber: phoneNumberData.value,
    })
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

onMounted(() => {
    init()
})

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

defineExpose({
    clear,
})
</script>

<template>
    <div class="iwFormInputContainer iwFormInputPhoneContainer"
         :class="$attrs.class"
         tabindex="0"
         @focus="phoneNumberInputRef?.focus()">
        <!-- Country code dropdown -->
        <select v-if="props.showCountryCode"
                class="iwFormInputPhoneCountrySelector"
                :disabled="($attrs.disabled as boolean)"
                v-model="selectedCountryCode"
                @change="onChange">
            <option v-for="(countryISOCode, idx) in props.countryCodeList"
                    :key="idx"
                    :value="countries[countryISOCode].countryCode">
                {{ countries[countryISOCode].flag }} (+{{ countries[countryISOCode].countryCode }})
            </option>
            <!-- Show unknown country code from API if not found in list -->
            <option v-if="unknownCountryCodeFromAPI"
                    :value="unknownCountryCodeFromAPI">
                (+{{ unknownCountryCodeFromAPI }})
            </option>
            <!-- 'Not applicable' option -->
            <option :value="phoneUtils.notApplicableOptionVal">
                N/A
            </option>
        </select>
        <!-- Phone number input -->
        <input v-bind="Object.assign({}, $attrs, { class: undefined })"
               class="iwFormInputPhone"
               ref="phoneNumberInputRef"
               type="tel"
               v-model="phoneNumberData"
               @blur="emit('blur', $event)"
               @change="onChange"
               @focus="emit('focus', $event)"
               @input="onInput" />
    </div>
</template>
