<script setup>
import { onMounted } from 'vue'
import '@unocss/reset/tailwind-compat.css'
import { useModalStore } from './stores/modal'

// initialize components based on data attribute selectors
onMounted(() => {
  import('~/components/Forms/IwForm/css/IwForm.css')
  import('~/components/Forms/IwForm/css/IwFormModal.css')
  import('~/components/Forms/IwForm/css/EditorTipTap.css')
  import('~/components/Forms/IwForm/css/EditorTipTapMenu.css')
  import('~/components/Forms/IwForm/css/EditorPreview.css')
  import('~/components/ViPaginatedTable/PaginatedTable.css')
  import('vue-multiselect/dist/vue-multiselect.css')
  import('tippy.js/dist/tippy.css')
})
</script>

<template>
  <div id="viApp"
       class="min-h-screen"
       :class="{ 'modal-open': useModalStore().showModal }">
    <LoadingIndicator />
    <NuxtPage class="mb-10 min-h-[90vh]" />
    <Modal></Modal>
    <Footer></Footer>
  </div>
</template>

<style>
body {
  background-color: #fcfcfc;
  color: rgba(0, 0, 0, 0.8);
  line-height: initial;
  min-height: 100svh;
  /* to avoid reflow when modal open */
  /* padding-right: 15px; */
}

#viApp.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

#modals {
  position: fixed;
  z-index: 60;
}

.dark body {
  background-color: #091a28;
  color: #ebf4f1;
  background: #091a28;
  background: -webkit-linear-gradient(to bottom, #091a28, #252838);
  background: linear-gradient(to bottom, #091a28, #252838);
}

svg.iconify {
  display: inline-block;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  filter: blur(0.1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.3s;
}

.layout-enter-from,
.layout-leave-to {
  filter: blur(0.1rem);
}

.copyright-footer {
  padding-left: 270px;
  padding-bottom: 10px;
  color: #ebf4f1;
}
</style>
