<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { BubbleMenu, Editor, EditorContent, mergeAttributes } from '@tiptap/vue-3'
import { Color as ExtColor } from '@tiptap/extension-color'
import ExtFontSize from './extensions/EditorTipTapFontSize'
import { highlightList as extractedHighlightList, default as ExtHighlight } from './extensions/TextStyleHighlight'
import ExtImage from '@tiptap/extension-image'
import ExtPlaceholder from '@tiptap/extension-placeholder'
import ExtUnderline from '@tiptap/extension-underline'
import ExtStarterKit from '@tiptap/starter-kit'
import { TextAlign as ExtTextAlign } from '@tiptap/extension-text-align'
import ExtTextStyle from '@tiptap/extension-text-style'
import ExtYoutube from '@tiptap/extension-youtube'
import { Icon } from '@iconify/vue'
import type { EditorView } from 'prosemirror-view/dist'
import type { Slice } from 'prosemirror-model/dist'
import EditorPreview from './EditorPreview.vue'
import EditorColorSelector from './addons/EditorColorSelector.vue'
import EditorContentSizeInfo from './addons/EditorContentSizeInfo.vue'
import EditorImageInsert from './addons/EditorImageInsert.vue'
import EditorUsageGuide from './addons/EditorUsageGuide.vue'
import EditorVideoEmbed from './addons/EditorVideoEmbed.vue'
import EditorButtonInsert from './addons/EditorButtonInsert.vue'
import ExtEditorButton, { type SetEditorButtonOptions } from './extensions/EditorButton'
import ExtEditorLink, { normalizeLink } from './extensions/EditorLink'
import { IwFormColor } from '../../utils/IwFormColor'
import { IwFormRule } from '../../utils/IwFormRule'
import { directive as vTippy } from 'vue-tippy'
import useResizeImage from '../../composables/useResizeImage'
import { uploaderFileTypes } from '../../utils/IwFormUploaderConfig'

///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////
const emit = defineEmits(['change'])
const identifier = (new Date()).getTime() + Math.random() * 10000

const props = defineProps({
    config: {
        type: Object as PropType<IwFormEditorConfig>,
        required: true
    },
    formInput: {
        type: Object as PropType<IwFormInputEditor>,
        required: true,
    },
    id: {
        type: String,
    },
    funcToastError: {
        type: Function as PropType<(msg: string) => void>,
    }
})

const defaultConfig: IwFormEditorConfig = {
    maxImageUploadPixel: 3000,
    maxImageSizeInMb: 2,
    placeholder: 'Write something ...',
    showLabel: false
}
const config: IwFormEditorConfig = Object.assign({}, defaultConfig, props.config)

const theEditor = ref<Editor>()
const useResize = useResizeImage()
/**
 * The current editor content that will be updated automatically on each content
 * update
 */
const editorHTMLContent = ref('')

/**
 * A Vue reference to the bubble menu bar
 *
 * ref is used for correctly identifying the different bubble menu bars between the
 * different EditorTipTap instances that have the same class. So the ref will
 * uniquely bind to the bubble menu for the current instance.
 */
const bubbleMenuRef = ref()

const previewRef = ref<typeof EditorPreview>()

const defaultLinkColor = '#2563eb'

let fontColor = ref<string>('#000000')
let fontSize = ref<number>(12)
const fontSizeOptions = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 40, 44, 48, 72]

/** Used for indicating the current highlight colour on the cursor */
const highlightIndicatorStyle = computed(() => {
    const transparent = IwFormColor.transparent.toHex()
    const color = highlightColorObj.value.toHex()

    return `linear-gradient(to bottom, ${transparent} 80%, ${color} 80%, ${color} 90%, ${transparent} 90%)`
})
const highlightColorObj = ref<IwFormColor>(IwFormColor.transparent)
const showHighlightDropdown = ref(false)

let menus: IwFormEditorMenus[]

const imageInsertRef = ref<InstanceType<typeof EditorImageInsert>>()
const youtubePreview = ref<InstanceType<typeof EditorVideoEmbed>>()
const btnInsertRef = ref<InstanceType<typeof EditorButtonInsert>>()
const showLinkDropdown = ref(false)
/** Indicate whether the current editor text selection has hyperlink */
const selectionHasLink = ref(false)
const hyperlinkData = ref('')

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
/**
 * Used for invoking editor's internal highlight module to highlight content
 */
function applyHighlightToContent() {
    const bgColor = highlightColorObj.value
    const builder = theEditor.value!.chain().focus()

    if (bgColor.isTransparent()) {
        builder.unsetHighlight().run()
    } else {
        builder.setHighlight({ color: bgColor.toHex() }).run()
    }
}

function getId(name: string) {
    return `${identifier}-${name}`
}

function handleDropOntoEditor(view: EditorView, event: DragEvent, slice: Slice, moved: boolean) {
    if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
        let droppedFile = event.dataTransfer.files[0] // the dropped file

        const acceptedFileTypes: string[] = [
            ...uploaderFileTypes.av1,
            ...uploaderFileTypes.apng,
            ...uploaderFileTypes.jpeg,
            ...uploaderFileTypes.gif,
            ...uploaderFileTypes.png,
            ...uploaderFileTypes.svg,
            ...uploaderFileTypes.webp,
        ]

        if (acceptedFileTypes.includes(droppedFile.type)) {
            const coordinates = view.posAtCoords({
                left: event.clientX, top: event.clientY
            });

            if (coordinates) {
                insertImageIntoEditor(view, { file: droppedFile, width: 60 }, coordinates.pos)
            } else {
                console.error('could not find Editor view coordinates')
            }
        } else {
            throw new Error("Unsupported image format.")
        }

        return true; // handled
    }

    return false; // not handled use default behaviour
}

/**
 * Insert an image file into the editor
 *
 * ### Example
 * ```ts
 * insertImageIntoEditor(editor.view, imageData, editor.view.state.selection.$anchor.pos)
 * ```
 *
 * @param view The editor view
 * @param imgProps The image's properties
 * @param pos The position in the editor that the image will be inserted at
 */
async function insertImageIntoEditor(
    view: EditorView,
    imgProps: {
        file: File,
        width?: number,
        height?: number,
    },
    pos: number,
) {
    const imgDryRun = new Image()
    let _URL = window.URL || window.webkitURL
    const imageFileUrl = _URL.createObjectURL(imgProps.file)

    const p = new Promise((resolve, reject) => {
        // Executed if the image is valid
        imgDryRun.onload = async function (_onLoadEvent: Event) {
            // Clean up no longer used image file validity test's object URL
            _URL.revokeObjectURL(imageFileUrl)

            // Use custom image size if given
            if (imgProps.width != null) {
                imgDryRun.width = imgProps.width
            }
            if (imgProps.height != null) {
                imgDryRun.height = imgProps.height
            }

            let fileToUpload: any

            try {
                fileToUpload = await useResize.compressImage(imgProps.file, 2, 2500)
            } catch (error: any) {
                return reject(error)
            }

            // Upload image to database
            if (!config.onImageUpload) {
                return reject(Error('onImageUpload props is not defined.'))
            }

            const imageUrl = await config.onImageUpload(fileToUpload)
            const width = imgDryRun.width.toString()
            const height = imgDryRun.height.toString()

            // Check image URL from database
            const image = new Image()

            // TODO: start the image loading spinner
            image.onload = function () {
                // @ts-ignore: TODO: refactor into a separate node
                theEditor.value.commands.setImage({ src: imageUrl, widthPercentage: width + '%' })
            }

            image.onerror = function () {
                return reject(Error('[Server error]: Image cannot be loaded'))
            }
            image.src = imageUrl
        }

        // Handle error such as undisplayable image data
        imgDryRun.onerror = () => {
            _URL.revokeObjectURL(imageFileUrl)
            return reject(Error('Image cannot be loaded'))
        }

        imgDryRun.src = imageFileUrl
    })

    try {
        await p
    } catch (error: any) {
        props.funcToastError?.(error.message)
    }
}

function initEditor(): Editor {
    return new Editor({
        editorProps: {
            handleDrop: handleDropOntoEditor,
            handleKeyDown(view, event) {
                // Toggle blockquote if matches 'C-S-B'
                if (event.ctrlKey && event.shiftKey && event.key.toUpperCase() == 'B') {
                    theEditor.value!.chain().focus().toggleBlockquote().run()
                    return true
                }
                return false
            },
        },
        extensions: [
            ExtColor,
            ExtEditorButton.configure({
                // Added `btn` class to apply hover style from Bootstrap
                class: 'btn',
            }),
            /**
             * Default priority for the link extension is 1000
             * https://stackoverflow.com/questions/1763235/span-inside-anchor-or-anchor-inside-span-or-doesnt-matter
             */
            ExtEditorLink,
            ExtFontSize,
            ExtHighlight.configure({
                multicolor: true,
            }),
            ExtImage
                .extend({
                    addAttributes() {
                        return {
                            ...this.parent?.(),
                            style: {
                                renderHTML: () => null,
                            },
                            widthPercentage: {
                                parseHTML: (element) => {
                                    if (element.style.width && element.style.width.toString().endsWith('%')) {
                                        return element.style.width
                                    }
                                },
                                renderHTML: ({ widthPercentage }) => {
                                    if (widthPercentage) {
                                        return {
                                            style: `width: ${widthPercentage}`,
                                        }
                                    }
                                },
                            },
                        }
                    },
                })
                .configure({
                    allowBase64: true,
                    inline: true,
                }),
            ExtPlaceholder.configure({ placeholder: config.placeholder, }),
            ExtStarterKit.configure({
                heading: {
                    levels: [1, 2, 3],
                },
                history: { newGroupDelay: 1000, }
            }),
            ExtTextAlign.configure({
                alignments: ['left', 'center', 'right', 'justify'],
                defaultAlignment: 'left',
                types: ['heading', 'paragraph', 'button'],
            }),
            /**
             * Default priority is 100. Higher priority tag will be placed outermost.
             *
             * How priority affects the tags order:
             * (ExtTextStyle set to priority 100): <a><s><span> text </span></s></a>
             * (ExtTextStyle set to priority 200): <a><span><s> text </s></span></a>
             *
             * <a> is always the outmost tag due to ExtEditorLink has priority of 1000.
             *
             * <span> should be placed outside of other styling tags like
             * <s> since it holds the font size value which needs to be
             * inherited to the children tags for them to be displayed in the
             * appropriate size.
             */
            ExtTextStyle.extend({
                priority: 200,
            }),
            ExtUnderline,
            ExtYoutube.extend({
                parseHTML() {
                    return [
                        {
                            tag: 'iframe'
                        }
                    ]
                },
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        paddingBottom: {
                            parseHTML: element => element.dataset.paddingBottom,
                            renderHTML: ({ paddingBottom }) => ({
                                'data-padding-bottom': paddingBottom,
                                style: `padding-bottom:${paddingBottom}`,
                            }),
                        },
                        width: {
                            parseHTML: element => element.dataset.width,
                            renderHTML: ({ width }) => ({
                                'data-width': width,
                                style: `width: ${width}`,
                            }),
                        },
                    }
                },
                renderHTML({ HTMLAttributes }) {
                    const HTMLAttributesForIframe = { ...HTMLAttributes }
                    delete HTMLAttributesForIframe.style

                    return [
                        'div',
                        mergeAttributes(
                            { 'data-youtube-video': '' },
                            { 'style': HTMLAttributes.style },
                            { 'style': 'position: relative; height: 0; margin-left: auto; margin-right: auto' },
                        ),
                        [
                            'iframe',
                            mergeAttributes(
                                this.options.HTMLAttributes,
                                // Referenced from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-youtube/src/youtube.ts#L168C1-L185C13
                                {
                                    width: this.options.width,
                                    height: this.options.height,
                                    allowfullscreen: this.options.allowFullscreen,
                                    autoplay: this.options.autoplay,
                                    ccLanguage: this.options.ccLanguage,
                                    ccLoadPolicy: this.options.ccLoadPolicy,
                                    disableKBcontrols: this.options.disableKBcontrols,
                                    enableIFrameApi: this.options.enableIFrameApi,
                                    endTime: this.options.endTime,
                                    interfaceLanguage: this.options.interfaceLanguage,
                                    ivLoadPolicy: this.options.ivLoadPolicy,
                                    loop: this.options.loop,
                                    modestBranding: this.options.modestBranding,
                                    origin: this.options.origin,
                                    playlist: this.options.playlist,
                                    progressBarColor: this.options.progressBarColor,
                                },
                                HTMLAttributesForIframe,
                                { style: 'position: absolute; height:100%; width: 100%' }
                            ),
                        ],
                    ]
                },
            }).configure({
                HTMLAttributes: {
                    allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
                    frameborder: '0',
                    loading: 'lazy',
                },
                allowFullscreen: true,
                enableIFrameApi: true,
            }),
        ],
        content: toRaw(config.content),
        onSelectionUpdate: ({ editor }) => {
            fontColor.value = rgbToHex(
                editor.getAttributes('textStyle').color
                || editor.getAttributes('button').color
            )
            highlightColorObj.value = IwFormColor.initFromString(
                editor.getAttributes('highlight').color
                || IwFormColor.transparent.toHex()
            )

            onSelectUpdateFontSize(editor as Editor)

            // Toggle button insert component to 'Insert' or 'Edit' mode
            if (editor.isActive('button')) {
                const attrs = editor.getAttributes('button') as SetEditorButtonOptions
                btnInsertRef.value!.load(attrs)
            } else {
                btnInsertRef.value?.unload()
            }

            if (editor.isActive('image')) {
                const attrs = editor.getAttributes('image') as { src: string, widthPercentage: string }
                imageInsertRef.value!.load(attrs)
            } else {
                imageInsertRef.value?.unload()
            }

            // Close dropdown
            toggleDropdown()

            // Check if current selection has hyperlink
            selectionHasLink.value = editor.isActive('link')
            if (selectionHasLink.value) {
                // Fill in hyperlink input if has hyperlink
                hyperlinkData.value = editor.getAttributes('link').href
            } else {
                hyperlinkData.value = ''
            }
        },
        onUpdate: ({ editor }) => {
            onEditorContentUpdate(editor.getHTML())
        }
    })
}

function initMenu(editor: Editor) {

    fontColor.value = rgbToHex(editor.getAttributes('textStyle').color)
    menus = [
        {
            type: 'color',
            hide() {
                showHighlightDropdown.value = false
            },
            toggle() {
                showHighlightDropdown.value = !showHighlightDropdown.value
            },
            onChange: function (color: IwFormColor) {
                // If the same colour is being applied, remove the highlight
                if (color.toHex() == highlightColorObj.value.toHex()) {
                    color = IwFormColor.transparent
                }
                highlightColorObj.value = color
                showHighlightDropdown.value = false

                applyHighlightToContent()
            },
            colorList: extractedHighlightList,
            icon: 'material-symbols:ink-highlighter-outline',
            label: 'Highlight',
            useOnBubbleMenu: true,
            toggleable: true,
        },
        {
            onClick: () => editor.chain().focus().toggleBold().run(),
            markOption: ['bold'],
            label: 'Bold',
            shortcutKey: 'C-B',
            toggleable: true,
            icon: 'material-symbols:format-bold',
            useOnBubbleMenu: true,
        },
        {
            onClick: () => editor.chain().focus().toggleItalic().run(),
            markOption: ['italic'],
            label: 'Italic',
            shortcutKey: 'C-I',
            toggleable: true,
            icon: 'material-symbols:format-italic',
            useOnBubbleMenu: true,
        },
        {
            onClick: () => editor.chain().focus().toggleUnderline().run(),
            markOption: ['underline'],
            label: 'Underline',
            shortcutKey: 'C-U',
            toggleable: true,
            icon: 'material-symbols:format-underlined',
            useOnBubbleMenu: true,
        },
        {
            onClick: () => editor.chain().focus().toggleStrike().run(),
            markOption: ['strike'],
            label: 'Strike',
            shortcutKey: 'C-S-S',
            toggleable: true,
            icon: 'material-symbols:strikethrough-s-rounded',
            useOnBubbleMenu: true,
        },
        {
            onClick: () => editor.chain().focus().toggleCodeBlock().run(),
            markOption: ['codeBlock'],
            label: 'Code Block',
            shortcutKey: 'C-E',
            toggleable: true,
            icon: 'material-symbols:code',
        },

        // paragraph: {
        //     onClick: () => editor.chain().focus().setParagraph().run(),
        //     disabled: () => !editor.can().chain().focus().setParagraph().run(),
        //     css: { 'is-active': editor.isActive('paragraph') },
        //     label: 'Paragraph',
        //     icon: 'mdi:format-paragraph',
        // },
        {
            onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
            markOption: ['heading', { level: 1 }],
            label: 'Heading 1',
            shortcutKey: 'C-A-1',
            toggleable: true,
            icon: 'gridicons:heading-h1',
            useOnBubbleMenu: false,
        },
        {
            onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
            markOption: ['heading', { level: 2 }],
            label: 'Heading 2',
            shortcutKey: 'C-A-2',
            toggleable: true,
            icon: 'gridicons:heading-h2',
            useOnBubbleMenu: false,
        },
        {
            onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
            label: 'Heading 3',
            markOption: ['heading', { level: 3 }],
            shortcutKey: 'C-A-3',
            toggleable: true,
            icon: 'gridicons:heading-h3',
            useOnBubbleMenu: false,
        },
        {
            onClick: () => editor.commands.setTextAlign('left'),
            label: 'Align Left',
            markOption: [{ textAlign: 'left' }],
            shortcutKey: 'C-S-L',
            icon: 'akar-icons:text-align-left',
        },
        {
            onClick: () => editor.commands.setTextAlign('center'),
            label: 'Align Center',
            markOption: [{ textAlign: 'center' }],
            shortcutKey: 'C-S-E',
            icon: 'akar-icons:text-align-center',
        },
        {
            onClick: () => editor.commands.setTextAlign('right'),
            label: 'Align Right',
            markOption: [{ textAlign: 'right' }],
            shortcutKey: 'C-S-R',
            icon: 'akar-icons:text-align-right',
        },
        {
            onClick: () => editor.commands.setTextAlign('justify'),
            label: 'Align Justify',
            markOption: [{ textAlign: 'justify' }],
            shortcutKey: 'C-S-J',
            icon: 'akar-icons:text-align-justified',
        },
        {
            onClick: () => editor.chain().focus().toggleOrderedList().run(),
            label: 'Ordered List',
            markOption: ['orderedList'],
            shortcutKey: 'C-S-7',
            toggleable: true,
            icon: 'material-symbols:format-list-numbered',
        },
        {
            onClick: () => editor.chain().focus().toggleBulletList().run(),
            label: 'Bullet List',
            markOption: ['bulletList'],
            toggleable: true,
            shortcutKey: 'C-S-8',
            icon: 'material-symbols:format-list-bulleted',
        },
        {
            onClick: () => editor.chain().focus().toggleBlockquote().run(),
            label: 'Block Quote',
            markOption: ['blockquote'],
            shortcutKey: 'C-S-B',
            toggleable: true,
            icon: 'material-symbols:format-quote',
        },
        {
            type: 'link',
            hide() {
                showLinkDropdown.value = false
            },
            toggle() {
                showLinkDropdown.value = !showLinkDropdown.value

                if (!showLinkDropdown.value) {
                    // Clear link input on dropdown close
                    hyperlinkData.value = ''
                } else {
                    hyperlinkData.value = editor.getAttributes('link').href
                }
            },
            onInsert(link: string | null) {
                const linkNormalized = normalizeLink(link)
                if (link) {
                    if (linkNormalized) {
                        // Allow to toggle hyperlink if there is an URL
                        editor.chain()
                            .focus()
                            .toggleLink({ href: linkNormalized })
                            .run()
                        selectionHasLink.value = editor.isActive('link')

                        if (selectionHasLink.value) {
                            editor.chain()
                                .focus()
                                .setColor(defaultLinkColor)
                                .setUnderline()
                                .run()
                        } else {
                            editor.chain()
                                .focus()
                                .unsetColor()
                                .unsetUnderline()
                                .run()
                        }
                        // Update font colour preview on the menu
                        fontColor.value = editor.getAttributes('textStyle').color
                    } else {
                        // If link is removed due to invalid link
                        props.funcToastError?.('Unable to insert invalid link')
                    }
                } else {
                    // Hyperlink will be removed if URL is absent
                    editor.chain().focus().unsetLink().run()
                }
            },
            label: 'Link',
            markOption: ['link'],
            toggleable: true,
            icon: 'ic:outline-link',
            // TODO: Create a custom extension to allow inserting link with its
            //       text and URL. `extension-link` only allows URL to be
            //       inserted.
            useOnBubbleMenu: true,
        },
        {
            type: 'image',
            onClick: () => {
                // maybe also handle image configuration for inserted images
                imageInsertRef.value!.toggle()
            },
            onInsert(file, width, height) {
                insertImageIntoEditor(editor.view, { file, width, height }, editor.view.state.selection.$anchor.pos)
            },
            onEdit(src, width) {
                // @ts-ignore: TODO: refactor into a separate node
                editor.commands.setImage({ src: src, widthPercentage: width })
            },
            label: 'Image',
            markOption: ['image'],
            toggleable: true,
            icon: 'material-symbols:add-photo-alternate-outline',
        },
        {
            type: 'video',
            hide: () => youtubePreview.value!.hide(),
            toggle: () => youtubePreview.value!.toggle(),
            onInsert(config) {
                editor.commands.setYoutubeVideo({
                    src: config.src,
                    width: config.width,
                    height: config.height,
                    // @ts-ignore: TODO: refactor into a separate node
                    paddingBottom: config.paddingBottom
                })
                youtubePreview.value!.hide()
            },
            label: 'Embed YouTube Video',
            markOption: ['youtube'],
            icon: 'mingcute:youtube-line',
            toggleable: true,
        },
        {
            type: 'button',
            hide: () => btnInsertRef.value!.hide(),
            toggle: () => btnInsertRef.value!.toggle(),
            onEdit(config) {
                // TODO: Find a way to edit text by attribute
                editor.commands.updateAttributes('button', config)
                btnInsertRef.value!.hide()
            },
            onInsert(config) {
                editor.commands.setButton(config)
                btnInsertRef.value!.hide()
            },
            label: 'Button',
            markOption: ['button'],
            icon: 'ic:baseline-smart-button',
            toggleable: true,
        },
        {
            onClick: () => editor.chain().focus().setHorizontalRule().run(),
            label: 'Horizontal Rule',
            icon: 'material-symbols:horizontal-rule',
        },
        {
            type: 'separator',
        },
        {
            onClick: () => {
                editor.chain().focus().unsetAllMarks().clearNodes().unsetFontSize().run()
            },
            label: 'Clear Formatting',
            icon: 'tabler:clear-formatting',
        },
        {
            onClick: () => editor.chain().focus().undo().run(),
            label: 'Undo',
            icon: 'material-symbols:undo',
            shortcutKey: 'C-Z',
        },
        {
            onClick: () => editor.chain().focus().redo().run(),
            label: 'Redo',
            shortcutKey: 'C-S-Z',
            icon: 'material-symbols:redo',
        },
    ]

}

function getCss(menu: IwFormEditorMenu) {
    let css = ''
    if (typeof menu.markOption !== 'undefined') {
        // @ts-ignore : typescript unable to recognize menu.markOption could be
        // resolved to "name" or "name, attributes"
        const isActive = theEditor.value!.isActive(...menu.markOption)
        css += isActive ? ' active' : ''

        if (menu.toggleable) {
            css += ' toggleable'
        }
    }
    return css
}

/**
 * Toggle a dropdown to open or close when a menu item is clicked
 */
function toggleDropdown(type?: IwFormEditorMenus['type'] | null) {
    for (const item of menus) {
        if ('hide' in item) {
            if (item.type !== type) {
                item.hide()
            } else {
                item.toggle()
            }
        }
    }
}

/**
 * Set focus to editor when the top or bottom padding is clicked
 */
function onClickEditorSetFocus(event: MouseEvent) {
    const editorElem = event.target as HTMLElement | null
    if (editorElem) {
        const computedStyle = getComputedStyle(editorElem)
        const paddingTop = Number.parseInt(computedStyle.paddingTop)
        const paddingBottom = Number.parseInt(computedStyle.paddingBottom)
        const {
            top: elementTop,
            bottom: elementBottom,
        } = editorElem.getBoundingClientRect()

        const isPaddingTopClicked = elementTop <= event.clientY
            && event.clientY <= elementTop + paddingTop
        const isPaddingBottomClicked = elementBottom - paddingBottom <= event.clientY
            && event.clientY <= elementBottom

        if (isPaddingTopClicked) {
            theEditor.value?.commands.focus('start')
        } else if (isPaddingBottomClicked) {
            theEditor.value?.commands.focus('end')
        }
    }
}

function onFontSizeChange() {
    theEditor.value!.chain().focus().setFontSize(fontSize.value + 'px').run()
}

/**
 * Return an object with the tippy config, along with the menu item tooltip
 *
 * @param menu The menu item option object
 */
function getTippyText(menu: Record<string, any>): Record<string, any> {
    const shortcutText = menu.shortcutKey ? `(${menu.shortcutKey})` : ''

    // NOTE: If menu.label == undefined, but shortcutText is defined,
    //       '(C-Z)' will be returned without the 'Undo' label
    return {
        content: `${menu.label ?? ''} ${shortcutText}`.trim(),
        inlinePositioning: true,
        /** Touch screen devices will need to 'Hold' to display the tooltip */
        touch: 'hold',
    }
}

function rgbToHex(rgbColor: string) {
    if (!rgbColor) return '#000000';

    if (rgbColor.startsWith('#')) {
        return rgbColor;
    }

    const values = rgbColor.match(/\d+/g);
    if (!values || values.length !== 3) {
        console.warn("Invalid RGB color format. Using IwFormColor...")

        return IwFormColor.initFromString(rgbColor).toHex()
    }

    const hexValues = values.map(value => {
        const hex = parseInt(value).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    });

    return "#" + hexValues.join("").toUpperCase();
}

function onSelectUpdateFontSize(editor: Editor) {
    let size = editor.getAttributes('textStyle').fontSize
    if (!size) size = '16'
    fontSize.value = size.replace('px', '')
}

function onColorInput($event: Event) {
    const color = rgbToHex(($event.target as HTMLInputElement)?.value)
    theEditor.value!.chain().focus().setColor(color).run()
    fontColor.value = color
}

/**
 * Handle any content update that will be emitted upstream
 *
 * @param content The raw editor content HTML
 */
function onEditorContentUpdate(content: string) {
    editorHTMLContent.value = content
    emit('change', content)
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////
onMounted(() => {
    theEditor.value = initEditor()
    editorHTMLContent.value = theEditor.value.getHTML()

    initMenu(theEditor.value)

    if (config.content) {
        emit('change', editorHTMLContent.value)
    }

    // Apply content size limiter on save
    if (config.maxContentSizeInBytes != null) {
        const lengthRule = IwFormRule.maxSizeInBytes({
            max: config.maxContentSizeInBytes,
            errMsg: 'The size of content exceeds the size limit'
        })

        if (props.formInput.rules) {
            props.formInput.rules.push(lengthRule)
        } else {
            props.formInput.rules = [lengthRule]
        }
    }
})

onUnmounted(() => {
    theEditor.value?.destroy()
})

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div :id="id"
         class="iwFormEditorTemplate">
        <EditorUsageGuide :usageGuide="config.usageGuide" />
        <span v-if="config.showPreviewBtn"
              class="iwFormEditorPreviewOpenText"
              v-tippy="'Click to preview rendered page'"
              @click="previewRef && previewRef.toggle()">
            Preview
            <Icon icon="ic:baseline-open-in-new" />
        </span>
        <div v-show="previewRef && previewRef.isVisible()"
             class="iwFormEditorPreviewBackdrop">
            <EditorPreview ref="previewRef"
                           :content="editorHTMLContent ?? ''" />
        </div>

        <div v-if="theEditor"
             class="iwFormEditor">
            <!--
                NOTE: @mousedown.prevent is used throughout the editor is to
                      prevent selected text from being deselected.
            -->
            <BubbleMenu :editor="theEditor"
                        :tippyOptions="{
                            theme: 'white-arrow no-border',
                            zIndex: 20,
                            popperOptions: {
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            // Disable popper flip
                                            fallbackPlacements: [],
                                        },
                                    },
                                ],
                            },
                        }">
                <div class="iwFormEditorBubbleMenu iwFormEditorMenuBar"
                     ref="bubbleMenuRef"
                     @mousedown.self.prevent="">
                    <span class="iwFormEditorMenuItem"
                          v-tippy="'Font Color'">
                        <input type="color"
                               @change="onColorInput"
                               :value="fontColor">
                    </span>
                    <span class="iwFormEditorMenuItem"
                          v-tippy="'Font Size'">
                        <select v-model="fontSize"
                                @change="onFontSizeChange">
                            <option v-for="size in fontSizeOptions">{{ size }}</option>
                        </select>
                    </span>
                </div>
            </BubbleMenu>

            <div class="iwFormEditorMenuBar"
                 @mousedown.self.prevent="">
                <template v-for="(menu, key) in menus"
                          :key="key">
                    <!-- Teleport to body is used as a temporary target, to wait for
                    bubbleMenuRef to be defined -->
                    <Teleport :to="bubbleMenuRef ?? 'body'"
                              :disabled="!menu['useOnBubbleMenu']">
                        <template v-if="!menu.type">
                            <span @mousedown.prevent="() => (menu.disabled?.() ?? true) && menu.onClick()"
                                  class="iwFormEditorMenuItem iwFormEditorMenuIconItem"
                                  :class="getCss(menu)"
                                  v-tippy="getTippyText(menu)">
                                <Icon :icon="menu.icon" />
                                <span v-if="config.showLabel">
                                    {{ menu.label }}
                                </span>
                            </span>
                        </template>
                        <template v-else-if="'color' == menu.type">
                            <span class="iwFormEditorMenuItem iwFormEditorMenuIconItem iwFormEditorMenuHighlightIndicator"
                                  :class="{ 'active': !highlightColorObj.isTransparent() }"
                                  :style="{ 'backgroundImage': highlightIndicatorStyle }"
                                  v-tippy="getTippyText(menu)"
                                  @mousedown.self.prevent="toggleDropdown(menu.type)">
                                <Icon :icon="menu.icon"
                                      @mousedown.prevent="toggleDropdown(menu.type)" />
                                <EditorColorSelector :colorList="menu.colorList"
                                                     :hidden="showHighlightDropdown"
                                                     :hintSelectedColor="highlightColorObj.toHex()"
                                                     :replaceDefault="menu.replaceDefault"
                                                     @change="(val) => menu.onChange(val)" />
                            </span>
                        </template>
                        <template v-else-if="'image' == menu.type">
                            <!--
                              Image insert button is using @click instead of the
                              usual @mousedown.prevent to stop bubble menu from
                              showing on top of the image insert modal.
                            -->
                            <span class="iwFormEditorMenuItem iwFormEditorMenuIconItem"
                                  :class="getCss(menu as unknown as IwFormEditorMenu)"
                                  v-tippy="getTippyText(menu)"
                                  @click.self="() => menu.onClick()">
                                <Icon :icon="menu.icon"
                                      @click="() => menu.onClick()" />
                                <EditorImageInsert :ref="el => imageInsertRef = (el as any)"
                                                   :maxImageSizeInMb="config.maxImageSizeInMb"
                                                   @insert="menu.onInsert"
                                                   @edit="menu.onEdit" />
                            </span>
                        </template>
                        <template v-else-if="'input' == menu.type">
                            <span class="iwFormEditorMenuItem">
                                <input :type="menu.inputType"
                                       v-tippy="getTippyText(menu)"
                                       @input="menu.onInput"
                                       :value="menu.value">
                            </span>
                        </template>
                        <template v-else-if="'link' == menu.type">
                            <span class="iwFormEditorMenuItem iwFormEditorMenuIconItem"
                                  :class="getCss(menu as unknown as IwFormEditorMenu)"
                                  v-tippy="getTippyText(menu)"
                                  @mousedown.self.prevent="toggleDropdown(menu.type)">
                                <Icon :icon="menu.icon"
                                      @mousedown.prevent="toggleDropdown(menu.type)" />
                                <div v-show="showLinkDropdown"
                                     class="iwFormEditorDropdown">
                                    <label class="iwFormEditorDropdownLabel"
                                           :for="getId('hyperlinkInput')">URL</label>
                                    <input :disabled="selectionHasLink"
                                           class="iwFormEditorDropdownInputText iwFormEditorDropdownInputUrl"
                                           name="hyperlinkInput"
                                           :id="getId('hyperlinkInput')"
                                           placeholder="Paste or type a link..."
                                           v-model="hyperlinkData"
                                           :title="hyperlinkData"
                                           type="url"
                                           @keyup.enter="() => menu.onInsert(hyperlinkData)" />
                                    <button class="iwFormEditorDropdownInsertBtn"
                                            :disabled="!hyperlinkData"
                                            type="button"
                                            @mousedown.prevent="() => menu.onInsert(hyperlinkData)">
                                        {{
                                            selectionHasLink
                                            ? 'Remove Link'
                                            : 'Insert Link'
                                        }}
                                    </button>
                                </div>
                            </span>
                        </template>
                        <template v-else-if="'video' == menu.type">
                            <span class="iwFormEditorMenuItem iwFormEditorMenuIconItem"
                                  :class="getCss(menu as unknown as IwFormEditorMenu)"
                                  v-tippy="getTippyText(menu)"
                                  @mousedown.self.prevent="toggleDropdown(menu.type)">
                                <Icon :icon="menu.icon"
                                      @mousedown.prevent="toggleDropdown(menu.type)" />
                                <EditorVideoEmbed :ref="(el: any) => youtubePreview = el"
                                                  @insert="menu.onInsert" />
                            </span>
                        </template>
                        <template v-else-if="'button' == menu.type">
                            <span class="iwFormEditorMenuItem iwFormEditorMenuIconItem"
                                  :class="getCss(menu as unknown as IwFormEditorMenu)"
                                  v-tippy="getTippyText(menu)"
                                  @mousedown.self.prevent="toggleDropdown(menu.type)">
                                <Icon :icon="menu.icon"
                                      @mousedown.prevent="toggleDropdown(menu.type)" />
                                <EditorButtonInsert :ref="(el: any) => btnInsertRef = el"
                                                    btnClassWhenNoUrl="register-trigger"
                                                    @edit="menu.onEdit"
                                                    @insert="menu.onInsert" />
                            </span>
                        </template>
                        <template v-else>
                            <span class="divider"></span>
                        </template>
                    </Teleport>
                </template>
            </div>
            <!-- Set focus to the TipTap editor when clicking on padding -->
            <editor-content :editor="theEditor"
                            class="iwFormEditorContent"
                            @click="onClickEditorSetFocus" />

            <EditorContentSizeInfo :content="editorHTMLContent"
                                   :maxContentSizeInBytes="config.maxContentSizeInBytes" />
        </div>
    </div>
</template>
