<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
defineOptions({
    // Disallow HTML attributes fallthrough to top-level element, instead
    // intercept and insert into internal input element.
    inheritAttrs: false,
})

////////////////////////////////////////////////////// @ Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    isEnabled: {
        type: Boolean,
        default: false
    },
    switchTrueValue: {
        type: [Boolean, Number, String],
        default: true
    },
    switchFalseValue: {
        type: [Boolean, Number, String],
        default: false
    },
    value: {
        type: [Boolean, Number, String],
    }
})

const emit = defineEmits<{
    (e: 'change', val: boolean | number | string): void,
}>()

////////////////////////////////////////////////////////// @ Variables
//////////////////////////////////////////////////////////////////////
const switchInput = ref(
    props.value === props.switchTrueValue
        ? props.value
        : (props.isEnabled ? props.switchTrueValue : props.switchFalseValue)
)

////////////////////////////////////////////////////////// @ Computed
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////// @ Functions
//////////////////////////////////////////////////////////////////////

/**
 * Event handler for change in number input
 */
function onChange() {
    if (null != switchInput.value) {
        emit('change', switchInput.value)
    }
}

////////////////////////////////////////////////////////// @ Lifecycle
//////////////////////////////////////////////////////////////////////
onMounted(() => {
    if (null != switchInput.value) {
        emit('change', switchInput.value)
    }
})

</script>

<template>
    <label class="iwFormInputSwitch">
        <input v-bind="$attrs"
               type="checkbox"
               v-model="switchInput"
               :true-value="props.switchTrueValue"
               :false-value="props.switchFalseValue"
               @change="onChange" />
        <span class="iwFormInputSwitchSlider"></span>
    </label>
</template>
