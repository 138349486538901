
<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

import { Icon } from '@iconify/vue'
import { directive as vTippy } from 'vue-tippy';

///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////

const emit = defineEmits(['insert'])

const preview = ref<HTMLIFrameElement>()
const isVisible = ref(false)

const aspectRatioLocked = ref(false)
const autoplayEnabled = ref(false)
const isMuted = ref(false)

const insertButtonDisabled = ref(true)
const identifier = (new Date()).getTime() + Math.random() * 10000

const aspectRatios = [
    { label: '16:9', value: (9 / 16) * 100 },
    { label: '4:3', value: (3 / 4) * 100 },
    { label: '9:16', value: (16 / 9) * 100 },
]

const widthOptions = [
    { label: '50%', value: 50 },
    { label: '60%', value: 60 },
    { label: '70%', value: 70 },
    { label: '80%', value: 80 },
    { label: '90%', value: 90 },
    { label: '100%', value: 100 },
]

const height = ref(0)
const widthPercentage = ref(100);
const paddingPercentage = ref(aspectRatios[0].value)

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
const calculatedPaddingPercentage = computed(() => (widthPercentage.value / 100) * paddingPercentage.value)

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Get the YouTube video ID part from the given link
 *
 * ### Example
 * ```
 * extractYoutubeVideoId('https://www.youtube.com/watch?v=jNQXAC9IVRw')
 * // returns: 'jNQXAC9IVRw'
 * ```
 */
function extractYoutubeVideoId(link: string | null): string | null {
    if (link) {
        const extracted = link.match(/(?<=youtu\.be\/|youtube\.com\/(watch\?v=|embed\/))[A-Z0-9_-]+/i)

        if (extracted) return extracted[0]
    }
    return null
}

function getId(name: string) {
    return `${identifier}-${name}`
}

/**
 * Hide Youtube embed window
 */
function hide() {
    isVisible.value = false

    // Re-insert `src` and remove `autoplay` to stop currently playing video
    const videoUrl = preview.value!.src
    if (videoUrl) {
        setYoutubeOption('autoplay', false)
    }
}

/**
 * Show Youtube embed window
 */
function show() {
    isVisible.value = true

    // Re-apply `autoplay` option to preview
    const videoUrl = preview.value!.src
    if (videoUrl && autoplayEnabled.value) {
        setYoutubeOption('autoplay', true)
    }
}

/**
 * Toggle Youtube embed window between show or hide
 */
function toggle() {
    if (isVisible.value) {
        hide()
    } else {
        show()
    }
}

/**
 * Handles input element's onInput for URL input
 *
 * @param url URL string
 */
function onInputUrl(url: string) {
    // Allowed hosts is matched greedily, as long as these prefixes exist in the text
    // NOTE: Subdomains (E.g. 'www') is omitted
    const allowedHosts = [
        'youtube.com',
        'youtu.be',
    ].map(l => l.trim().replace('.', '\\.'))

    const urlRegex = new RegExp(
        `(${allowedHosts.join('|')})` // Match hosts
        + '[-a-z\\d@:%._\\+~#?&=/]+', // Match path
        'gi'
    )
    const embedAutoplay = /autoplay=(?:1|true)/i.test(url)

    // Enable autoplay if the URL has it enabled
    if (embedAutoplay) {
        autoplayEnabled.value = true
    }

    const links = url.match(urlRegex)
    const fullUrl = links ? `https://${links[0]}` : null

    const youtubeVideoId = extractYoutubeVideoId(fullUrl)
    if (youtubeVideoId) {
        setPreviewVideo(`https://www.youtube.com/embed/${youtubeVideoId}`)
    } else {
        setPreviewVideo(fullUrl)
    }
}

/**
 * Handles button click to insert embed video
 */
function onInsert() {
    emit('insert', {
        autoplayEnabled: autoplayEnabled,
        height: height.value,
        paddingBottom: calculatedPaddingPercentage.value + '%',
        src: preview.value!.src,
        width: widthPercentage.value + '%',
    })
}

/**
 * Set the IFrame src attribute
 */
function setPreviewVideo(url: string | null) {
    const previewElem = preview.value!
    if (url) {
        insertButtonDisabled.value = false

        previewElem.src = url
        previewElem.style.display = 'block'

        setYoutubeOption('autoplay', autoplayEnabled.value)
    } else {
        insertButtonDisabled.value = true

        previewElem.removeAttribute('src')
        previewElem.style.display = 'none'
    }
}

/**
 * Set or unset an option on the Youtube video URL
 *
 * ### Example
 * ```ts
 * setYoutubeOption('autoplay', 1)
 * // Saves URL as 'https://www.youtube.com/embed/XXXXX?autoplay=1'

 * setYoutubeOption('autoplay', true)
 * // Saves URL as 'https://www.youtube.com/embed/XXXXX?autoplay=1'
 * ```
 */
function setYoutubeOption(option: string, value: string | boolean | 0 | 1) {
    const previewElem = preview.value!
    const url = previewElem.src

    if (value === 0 || value === false) {
        value = '0'
    } else if (value === 1 || value === true) {
        value = '1'
    }

    if (url) {
        const queryStartIdx = url.indexOf('?')
        if (queryStartIdx !== -1) {
            const urlWithoutQuery = url.substring(0, queryStartIdx)
            const queries = new URLSearchParams(url.substring(queryStartIdx + 1))
            queries.set(option, value)

            previewElem.src = `${urlWithoutQuery}?${queries.toString()}`
        } else {
            previewElem.src = `${url}?${option}=${value}`
        }
    }
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

defineExpose({
    hide,
    show,
    toggle,
})
</script>

<template>
    <Transition name="fade">
        <div v-show="isVisible"
             class="iwFormEditorDropdown"
             title=""> <!-- Stop title inheritance -->
            <label class="iwFormEditorDropdownLabel"
                   :for="getId('youtubeLink')">
                YouTube URL
            </label>
            <input type="text"
                   autocomplete="off"
                   class="iwFormEditorDropdownInputText iwFormEditorDropdownInputUrl"
                   name="youtubeLink"
                   :id="getId('youtubeLink')"
                   placeholder="www.youtube.com  |  <iframe>...</iframe>"
                   size="40"
                   @input="(ev) => onInputUrl((ev.target as HTMLInputElement).value)" />

            <iframe v-show="preview?.src"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    class="iwFormEditorDropdownIframe iwFormEditorDropdownMarginInput"
                    frameborder="0"
                    height="144"
                    ref="preview"
                    width="256">
            </iframe>

            <section class="iwFormEditorDropdownConfigSection iwFormEditorDropdownMarginSection">
                <!-- Width Dropdown -->
                <span class="inline-block">
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('width')">
                        <Icon class="iwFormEditorDropdownConfigInputLabelIcon"
                              icon="material-symbols:help-outline-rounded"
                              v-tippy="{
                                  content: 'Based on the width of the article',
                              }" />
                        Width%
                    </label>
                    <select class="iwFormEditorDropdownConfigInputSelect"
                            name="width"
                            :id="getId('width')"
                            v-model="widthPercentage">
                        <option v-for="option in widthOptions"
                                :value="option.value"
                                :key="option.value">
                            {{ option.label }}
                        </option>
                    </select>
                </span>
                <!-- Aspect Ratio Dropdown -->
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('aspectRatio')">
                        <Icon class="iwFormEditorDropdownConfigInputLabelIcon"
                              icon="material-symbols:help-outline-rounded"
                              v-tippy="{
                                  content: '<b>Select based on video size</b><br>'
                                      + '<b>16:9 (Widescreen)</b>: Standard YouTube video.<br>'
                                      + '<b>4:3 (Classic)</b>: Less common YouTube video.<br>'
                                      + '<b>9:16 (Vertical)</b>: Mobile YouTube video.',
                                  allowHTML: true,
                              }" />
                        Aspect Ratio
                    </label>
                    <select class="iwFormEditorDropdownConfigInputSelect"
                            name="aspectRatio"
                            :id="getId('aspectRatio')"
                            v-model="paddingPercentage">
                        <option v-for="ratio in aspectRatios"
                                :value="ratio.value"
                                :key="ratio.value">
                            {{ ratio.label }}
                        </option>
                    </select>
                </span>
                <!-- Autoplay -->
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('autoplay')">
                        Autoplay
                    </label>
                    <input type="checkbox"
                           class="iwFormEditorDropdownConfigInputCheckbox"
                           name="autoplay"
                           :id="getId('autoplay')"
                           v-model="autoplayEnabled"
                           @change="setYoutubeOption('autoplay', autoplayEnabled)">
                </span>
                <!-- Mute -->
                <span>
                    <label class="iwFormEditorDropdownConfigInputLabel"
                           :for="getId('mute')">
                        <Icon class="iwFormEditorDropdownConfigInputLabelIcon"
                              icon="material-symbols:help-outline-rounded"
                              v-tippy="'May allow videos to be autoplayed on more devices'" />
                        Muted
                    </label>
                    <input type="checkbox"
                           class="iwFormEditorDropdownConfigInputCheckbox"
                           name="muted"
                           :id="getId('muted')"
                           v-model="isMuted"
                           @change="setYoutubeOption('mute', isMuted)">
                </span>
            </section>

            <input type="button"
                   class="iwFormEditorDropdownInsertBtn iwFormEditorDropdownMarginSection"
                   :disabled="insertButtonDisabled"
                   value="Insert"
                   @mousedown.prevent="onInsert()">
        </div>
    </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .25s
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0
}
</style>
