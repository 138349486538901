import { ref } from 'vue'
import IwFormInputSelectConfig from "../utils/IwFormInputSelectConfig"

const useVueMultiSelect = (config: IwFormInputSelectConfig) => {
    /** Indicate that the user has started typing into the search field */
    const isUserTyping = ref(false)
    /** The key that stores the option value (E.g. `'value'`) */
    const keyName = config.keyName
    const selectedOption = ref<IwFormInputSelectOption | Array<IwFormInputSelectOption>>()

    function getLabelBy(config: IwFormInputSelectConfig) {
        return config.isOptionObject ? config.labelName : null
    }

    function getSelectedKeys(): IwFormInputSelectedKeys | null {
        if (!Array.isArray(selectedOption.value)) {
            if (selectedOption.value) {
                return selectedOption.value![keyName]
            }
            return null
        } else {
            const keys: any = []
            selectedOption.value.forEach(item => keys.push(item[keyName]))
            return keys
        }
    }

    function getTrackBy(config: IwFormInputSelectConfig) {
        return config.isOptionObject ? config.keyName : null
    }

    /**
     * Update the selected option by matching the selection by the option list
     *
     * New selected option will be inserted into `selectedOption`
     */
    function updateSelected(selection?: string | number | Array<string | number> | null) {
        if (selection == null) {
            selectedOption.value = undefined
            return
        }

        if (Array.isArray(selection)) {
            if (!config.multiple) {
                console.error('selected value passed as an array however "multiple" option is false')
                return
            }

            const validSelection = []
            for (const item of selection) {
                // NOTE: Will possibly match `number` against `string`
                const found = config.options.find(opt => opt[keyName] == item)

                if (found) {
                    validSelection.push(found)
                } else {
                    console.error(`${item} not found in options`)
                    console.error(config.options)
                }
            }
            selectedOption.value = validSelection
        } else {
            selectedOption.value = config.options.find(
                // NOTE: Will possibly match `number` against `string`
                opt => opt[keyName] == selection
            )
        }
    }

    function onInput(value: any, id: any) {
        isUserTyping.value = true
    }


    function onReset() {
        for (const option of config.options) {
            if (option.value === '' || option.value === null) {
                selectedOption.value = option
                return
            }
        }
        selectedOption.value = undefined
    }

    return {
        // variables
        isUserTyping,
        keyName,
        selectedOption,

        // functions
        getLabelBy,
        getSelectedKeys,
        getTrackBy,
        updateSelected,
        onInput,
        onReset,
    }
}

export default useVueMultiSelect
