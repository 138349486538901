import Compressor from 'compressorjs'

export const useResizeImage = () => {
    function compressWithoutConversion(
        file: File | Blob,
        containerWidth?: number
    ): Promise<Blob> {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: containerWidth,
                quality: 0.8,
                convertSize: Infinity,
                success(result) {
                    resolve(new File([result], file.name))
                },
                error(err) {
                    reject(err)
                },
            })
        })
    }

    function compressToJPEG(
        file: File | Blob,
        maxImageSizeInMb: number,
        containerWidth?: number
    ): Promise<Blob> {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: containerWidth,
                quality: 0.8,
                // Files whose file type is included in the convertTypes
                // list, and whose file size exceeds convertSize would be
                // converted to JPEGs.
                convertTypes: file.type,
                convertSize: maxImageSizeInMb,
                success(result) {
                    if (result.size < convertFromMbToBytes(maxImageSizeInMb)) {
                        resolve(new File(
                            [result],
                            file.name.replace(
                                /(\.\w+)?$/,
                                '.jpeg',
                            ),
                        ))
                    } else {
                        reject(new Error('The uploaded file is still too large after being compressed.'))
                    }
                },
                error(err) {
                    reject(err)
                },
            })
        })
    }

    /***
     *  @param file The image file to compress
     *  @param maxImageSizeInMb  Max image size in MB that allow to upload
     *  @param containerWidth The max-width of the image container
     *  @param options An object containing the types of files and their handling options
     */
    async function compressImage(
        file: File,
        maxImageSizeInMb: number,
        containerWidth?: number,
        options?: { compressibleTypes?: string[], nonCompressibleTypes?: string[] }
    ): Promise<Blob> {
        const maxImageSizeInBytes = convertFromMbToBytes(maxImageSizeInMb)

        const compressibleTypes = ['image/png', 'image/webp']
            .concat(options?.compressibleTypes ?? [])
        const nonCompressibleTypes = ['image/apng', 'image/gif']
            .concat(options?.nonCompressibleTypes ?? [])

        if (file.size < maxImageSizeInBytes) {
            return file
        }

        if (nonCompressibleTypes.includes(file.type)) {
            throw new Error('Failed to compress the uploaded file. File must be smaller than ' + maxImageSizeInMb + 'MB.')
        }

        if (compressibleTypes.includes(file.type)) {
            const compressedFile = await compressWithoutConversion(file, containerWidth)

            if (compressedFile.size < maxImageSizeInBytes) {
                return compressedFile
            }
        }

        return compressToJPEG(file, maxImageSizeInMb, containerWidth)
    }

    function convertFromMbToBytes(value: number) {
        return (value * 1024 * 1024)
    }

    return { compressWithoutConversion, compressToJPEG, compressImage }
}

export default useResizeImage
