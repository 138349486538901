<script setup lang='ts'>
// @unocss-include
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { Icon } from '@iconify/vue'
import { ref } from 'vue'

import { directive as vTippy } from 'vue-tippy'
///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////
const emit = defineEmits(['close', 'left-btn-click', 'right-btn-click'])

const props = defineProps({
    height: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
    },
    iconColor: {
        type: String,
        default: '',
    },
    isVisible: {
        type: Boolean,
        default: false,
    },
    leftBtnLabel: {
        type: String,
        default: 'Cancel',
    },
    leftBtnIsPrimary: {
        type: Boolean,
        default: false,
    },
    rightBtnFunc: {
        type: Function as PropType<() => Promise<any>>,
    },
    rightBtnLabel: {
        type: String,
        default: 'Yes',
    },
    rightBtnIsPrimary: {
        type: Boolean,
        default: true,
    },
    showFooter: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    width: {
        type: String,
        default: '!max-w-60%',
    },
})

const rightBtnCss = 'iwFormModalFooterBtn ' + props.rightBtnIsPrimary ? 'primaryBtn' : ''
const modalRef = ref<HTMLElement>()

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
watch(() => props.isVisible, async (isVisible) => {
    // Wait for the modal v-if to update after isVisible change
    await nextTick()

    // Focus on the modal for making 'Esc' key closing work, when modal is shown
    if (isVisible && modalRef.value) {
        modalRef.value.focus()
    }

    document.body.style.overflow = isVisible ? 'hidden' : 'auto'
})
//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
function onModalClose() {
    emit('close')
}

function onLeftBtnClick() {
    emit('left-btn-click');
}

async function onRightBtnClick() {
    if (props.rightBtnFunc) {
        await props.rightBtnFunc();
    }
    emit('right-btn-click');
}
/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

onBeforeUnmount(() => {
    // Remove body `overflow: hidden` and emit modal close.
    // Overflow removal is done separately in cases where the `props.isVisible`
    // `watch` was not able to be executed before unmount happens.
    document.body.style.overflow = 'auto'
    onModalClose()
})

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

</script>

<template>
    <Teleport to="body">
        <!-- FIXME: Modal also closes when closing file input modal with Esc -->
        <div v-if="isVisible"
             tabindex="-1"
             class="iwFormModalBackdrop"
             ref="modalRef"
             @keyup.esc.exact.self="onModalClose">
            <div :class="`iwFormModalScreen ${width} ${height}`">
                <div @click.stop
                     class="iwFormModal">
                    <div class="iwFormModalHeader">

                        <button @click="onModalClose"
                                type="button"
                                class="iwFormModalCloseBtn"
                                v-tippy="'Press \'Esc\' key to close'">
                            <Icon icon="material-symbols:close"
                                  class="iwFormModalCloseBtnIcon"></Icon>
                            <span>Close modal</span>
                        </button>
                    </div>

                    <div class="iwFormModalBody">
                        <div v-if="icon"
                             class="iwFormModalTitleIconWrapper">
                            <Icon :class="'iwFormModalTitleIcon ' + iconColor"
                                  :icon="icon" />
                        </div>

                        <h3 class="iwFormModalTitle">
                            {{ title }}
                        </h3>
                        <h4 v-if="subtitle"
                            class="iwFormModalSubtitle">
                            {{ subtitle }}
                        </h4>
                        <slot></slot>
                    </div>

                    <template v-if="showFooter">
                        <div class="iwFormModalFooter">
                            <slot name="footer-buttons">
                                <button type="button"
                                        class="iwFormModalFooterBtn"
                                        @click="onLeftBtnClick"
                                        :class="{ 'primaryBtn': leftBtnIsPrimary }">
                                    {{ leftBtnLabel }}
                                </button>
                                <ButtonSpinner :label="rightBtnLabel"
                                               :onClick="onRightBtnClick" />
                            </slot>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
</style>
