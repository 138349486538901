<script setup lang='ts'>
import { type PropType, computed, onErrorCaptured, onMounted, ref } from 'vue'
import { Icon } from '@iconify/vue';
import IwFormConfig from '../utils/IwFormConfig';
import EasepickCalendar from './EasepickCalendar.vue';
import VueMultiSelect from './VueMultiSelect.vue';
import IwFormInputNumber from './IwFormInputNumber.vue';
import IwFormInputPhone from './IwFormInputPhone.vue';
import IwFormInputRadio from './IwFormInputRadio.vue';
import IwFormInputRangeSlider from './IwFormInputRangeSlider.vue'
import IwFormUploader from './IwFormUploader.vue';
import useIwForm, { setRequired } from '../composables/useIwForm';
import dayjs from 'dayjs';
import IwFormBtn from './IwFormBtn.vue';
import IwFormLabel from './IwFormLabel.vue';
import Editor from './Editor/EditorTipTap.vue'
import EditorTextArea from './Editor/EditorTextArea.vue'
import { directive as tippyDirective } from 'vue-tippy'

//////////////////////////////////////////////////////////////////////
//  Emit & Props
//////////////////////////////////////////////////////////////////////

/**
 * change - event for single input field changes
 * dataUpdated - event when any input field is updated
 */
const emit = defineEmits(['change', 'data-updated', 'input-reset'])

const vTippy = tippyDirective

const IwFormTypeTextGroup: Array<IIwFormType> = [
  'text',
  'email',
  'password',
]

const props = defineProps({
  // required
  myForm: {
    type: Object as PropType<IwFormConfig>,
    required: true,
  },

  // optional, sorted by alphabet
  cancelText: {
    type: String,
    default: 'Cancel',
  },
  // canSubmitAgain: {
  //   type: Boolean,
  //   default: true,
  // },
  css: {
    type: Object as PropType<IwFormStyle>,
    default: {
      cssSubmitBtnWrapper: 'iwFormInputWrapper',
      cssResetBtnWrapper: 'iwFormResetFilterBtnWrapper',
    },
  },
  funcToastError: {
    required: false,
    type: Function as PropType<(msg: string) => void>,
  },
  /**
   * A hint to make the submit button enabled for form submission
   */
  isModified: {
    type: Boolean,
    default: false,
  },
  isReadOnly: {
    type: Boolean,
    default: false,
  },
  foldLabel: {
    type: String,
    default: 'Show more >'
  },
  onSubmit: {
    type: Function as PropType<IwFormOnSubmit>,
    required: false,
  },
  onReset: {
    type: Function,
    required: false,
  },
  resetIgnored: {
    type: Object as PropType<IwFormResetIgnored>,
    default: () => [],
  },
  // resetOnSubmit: {
  //   type: Boolean,
  //   default: true,
  // },
  resetText: {
    type: String,
    default: 'Reset',
  },
  showCancelBtn: {
    type: Boolean,
    default: false,
  },
  showFoldBtn: {
    type: Boolean,
    default: false,
  },
  showHelperText: {
    type: Boolean,
    default: true,
  },
  showResetBtn: {
    type: Boolean,
    default: false,
  },
  showSubmitBtn: {
    type: Boolean,
    default: true,
  },
  showSubmitLoading: {
    type: Boolean,
    default: true,
  },
  showCloseBtn: {
    type: Boolean,
    default: false,
  },
  submitAgainText: {
    type: String,
  },
  submitBtnAlwaysEnabled: {
    type: Boolean,
    default: false,
  },
  submitText: {
    type: String,
    default: 'Submit',
  },
  title: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
});

//////////////////////////////////////////////////////////////////////
//  Variables
//////////////////////////////////////////////////////////////////////
const identifier = (new Date()).getTime() + Math.random() * 10000
const formSubmitAgainText = props.submitAgainText ?? props.submitText
const isFormModified = ref(false)
/** Define that the form submit button is enabled for submission */
const isSubmitBtnEnabled = computed(() => {
  return props.submitBtnAlwaysEnabled || props.isModified || isFormModified.value
})

let isMounted = false
let timerOfDataUpdated: ReturnType<typeof setTimeout>;

const myForm = ref(props.myForm)

const {
  // variables
  errors,
  formErrorMsg,
  inputRefs,
  keys,
  myFormData,
  totalSubmission,

  // functions
  formOnReset,
  formOnSubmit,
  getAriaLabel,
  getCss,
  getFormData,
  getFormGroupSubmitLabel,
  getInputCss,
  initFormData,
  initRenderCallback,
  isDisabled,
  isVisible,
  onBlur,
  onChange: useOnChange,
  onFocus,
  onInput: useOnInput,
  onSelectSearch,
  submitIsLoading,
  validate,
  validateAll: useValidateAll,
} = useIwForm({
  myForm: myForm.value,
  onSubmit: props.onSubmit,
  onReset: props.onReset,
  resetIgnored: props.resetIgnored,
  funcToastError: props.funcToastError,
})
const folded = ref(true); // Start with folded state as true

const toggleFolded = () => {
  folded.value = !folded.value;
};

//////////////////////////////////////////////////////////////////////
//  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Register template ref by the given input name
 *
 * ### Example
 * ```vue
 * <Comp :ref="(el: HTMLElement) => createRef(item.name, el)" />
 * ```
 */
function createRef(inputName: string, el: Element | ComponentPublicInstance | null) {
  inputRefs.value[inputName] = el
}

/**
 * @param item IwFormInputDate
 * @param val Date[] an array of Date objects for single date or a range of date (i.e. start date to end date)
*/
function dateOnChange(item: IwFormInputDate, val: Date[]) {
  let res: Date[] | string = val
  if (1 == val.length) {
    let dateFormat = 'YYYY-MM-DD'
    if (item.dateOptions.enableTimePicker) {
      dateFormat = 'YYYY-MM-DD HH:mm'
    }
    res = dayjs(val[0]).format(dateFormat)
    myFormData.value[item.name] = res
  } else {
    myFormData.value[item.name] = val
  }

  if (validate(item, val)) {
    delete errors.value[item.name]
  }

  onChange(item, res)
  onFormModified()
}

function debounce(func: Function, delay: number) {
  return function (this: unknown, ...args: any[]) {
    clearTimeout(timerOfDataUpdated);
    timerOfDataUpdated = setTimeout(() => func.apply(this, args), delay);
  }
}

function getId(item: IwFormInputCore) {
  return `${identifier}-${item.name}`
}

function inputOnReset(item: IwFormInputCore) {
  myFormData.value[item.name] = null
  emit('input-reset', { item })
}

function isFormDataModified(): boolean {
  return isFormModified.value
}

async function myFormOnSubmit(ev: Event) {
  try {
    submitIsLoading.value = true
    isFormModified.value = false
    await formOnSubmit(ev)
    submitIsLoading.value = false
  } catch (e) {
    throw e
  } finally {
    isFormModified.value = true
  }
}

/**
 * called when any of the inputs value has changed
 *
 * NOTE: Only a select few non-user-actions are toggled to `false` currently.
 */
function onFormModified(isUserAction = true) {
  if (isMounted) {
    isFormModified.value = true
    debounce(() => {
      emit('data-updated', myFormData.value, isUserAction)
    }, 500)()
  }
}

async function onChange(item: IwFormInputCore, val: any, isUserAction = true, ...extra: any[]) {
  useOnChange(item, val)

  emit('change', { item, val, ...extra })
  onFormModified(isUserAction)
}

function onInput(item: IwFormInputCore, val: any) {
  useOnInput(item, val)
  onFormModified()
}

function phoneInputOnChange(
  item: IwFormInputPhone,
  data: {
    countryCode: IwFormPhoneCountryCodeWithUnknown,
    phoneNumber: string,
  },
  isInitEvent = false,
) {
  if (item.countryCodeDataKey) {
    myFormData.value[item.countryCodeDataKey] = data.countryCode
    onChange(item, data.phoneNumber, !isInitEvent)
  } else {
    onChange(item, data.countryCode + data.phoneNumber, !isInitEvent)
  }
}

function selectInputOnChange(item: IwFormInputSelectCore,
  selectedKeys: IwFormInputSelectedKeys,
  selectedRaw: IwFormInputSelectedOption | IwFormInputSelectedOption[],
  justSelected: IwFormInputSelectedOption,
  theForm: IwFormConfig
) {
  const isUserAction = true
  onChange(item, selectedKeys, isUserAction, selectedRaw, justSelected, theForm)
}

function selectInputOnInit(item: IwFormInputSelectCore,
  selectedKeys: IwFormInputSelectedKeys,
  selectedRaw: IwFormInputSelectedOption | IwFormInputSelectedOption[],
  justSelected: IwFormInputSelectedOption,
  theForm: IwFormConfig
) {
  const isUserAction = false
  onChange(item, selectedKeys, isUserAction, selectedRaw, justSelected, theForm)
}

function validateForm(): boolean {
  return useValidateAll();
}
//////////////////////////////////////////////////////////////////////
// Lifecycles
//////////////////////////////////////////////////////////////////////
onMounted(() => {
  isMounted = true
})

/**
 * Capture error thrown in a child component
 *
 * TODO: doesn't seem to capture async callback errors
 */
onErrorCaptured((err, instance) => {
  if (myForm.value.onError) {
    myForm.value.onError(err.message)
  }

  let instanceIdentifier
  if (instance) {
    const componentId = instance['id'] ? `#${instance['id']}` : ''
    const classes = instance?.$el?.className?.replace?.(/(^| )/g, '.')

    instanceIdentifier = `${componentId}${classes}`
  }

  console.error(`[Component${instanceIdentifier ? ` ${instanceIdentifier}` : ''}] ${err.message}`)
  throw new Error(err.message)
})
//////////////////////////////////////////////////////////////////////
// export & expose
/////////////////////////////////////////////////////////////////////
defineExpose({ getFormData, isFormDataModified, validateForm })

//////////////////////////////////////////////////////////////////////
// init
//////////////////////////////////////////////////////////////////////
initFormData();
initRenderCallback();



</script>

<template>
  <div class="iwFormWrapper">
    <slot name='buttonsTop'></slot>

    <div :class="css.cssShowFoldBtnWrapper ?? 'iwFormShowFoldBtnWrapper'">
      <template v-if="showFoldBtn">
        <button @click="toggleFolded"
                class="iwFormShowFoldBtn"
                type="button">
          {{ folded ? foldLabel : 'Show Less <' }}
             </button>
      </template>
    </div>

    <form :class="myForm.cssForm"
          :novalidate="true"
          @submit.prevent.stop='myFormOnSubmit'
          @reset.prevent.stop='formOnReset'>

      <div v-for="(group, groupKey) in  myForm.formGroups "
           :key="groupKey"
           :class="[group.css ?? 'iwFormGroup']">
        <template v-for="(item, idx) in  group.formInputs "
                  :key="identifier + idx">
          <div v-if="(!(item as IwFormInputText).foldable || (!folded && (item as IwFormInputText).foldable))"
               v-show="isVisible(item as IwFormInputCore)"
               :class="getCss(item, { cssArray: [item.cssWrapper ?? 'iwFormInputWrapper'], cssObj: { iwFormReadOnly: isReadOnly } })">
            <template name="label"
                      v-if="'label' === (item.type)">
              <div :class="getCss(item)">
                {{ item.label }}
                <span v-if="item.tooltip">
                  <Icon icon="material-symbols:contact-support-outline-rounded"
                        class="iwFormInputLabelInlineIcon mb-[5px]"
                        v-tippy="item.tooltip" />
                </span>
              </div>
            </template>

            <template name="separator"
                      v-else-if="'separator' === (item.type)">
              <hr :class="getCss(item, { default: 'iwFormHr' })">
            </template>

            <template name="text-group"
                      v-else-if="'textarea' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <textarea :aria-label="getAriaLabel(item)"
                        :autocomplete="item.autocomplete ?? 'on'"
                        :class="getInputCss(item)"
                        :disabled="isDisabled(item.disabled, isReadOnly)"
                        :id="getId(item)"
                        :key="keys[item.name]"
                        :name="item.name"
                        :placeholder="item.placeholder"
                        :ref="el => createRef(item.name, el)"
                        :required="setRequired(myFormData, item)"
                        :rows="item.textAreaRows ?? 4"
                        :rules="item.rules"
                        :type="item.type"
                        :value="myFormData[item.name]"
                        @blur="(_) => onBlur(item, myFormData[item.name])"
                        @change="(event) => onChange(item, (event.target as HTMLTextAreaElement).value)"
                        @focus="(_) => onFocus(item, myFormData[item.name])"
                        @input="(event) => onInput(item, (event.target as HTMLTextAreaElement).value)"></textarea>
              <p v-if="showHelperText"
                 class="iwFormInputHelperText">
                <template v-if="errors[item.name]">
                  <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                </template>
                <template v-else> {{ item.helperText }} </template>
              </p>

            </template>

            <template name="text-group"
                      v-else-if='IwFormTypeTextGroup.indexOf(item.type) >= 0'>
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />

              <div class="iwFormInputContainer">
                <div v-if="item.showPrefixIcon"
                     class="iwFormInputPrependIcon">
                  <Icon class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        :icon="item.prefixIcon!"></Icon>
                </div>
                <div v-if="(item as IwFormInputText).showPassword"
                     class="iwFormInputSuffixIcon">
                  <Icon class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        :icon="item.type == 'password' ? 'mdi:eye-off' : 'mdi:eye'"
                        @click="item.type = item.type == 'password' ? 'text' : 'password'">
                  </Icon>
                </div>
                <input :aria-label="getAriaLabel(item)"
                       :autocomplete="item.autocomplete ?? 'on'"
                       :class="getInputCss(item)"
                       :disabled="isDisabled(item.disabled, isReadOnly)"
                       :id="getId(item)"
                       :key="keys[item.name]"
                       :name="item.name"
                       :placeholder="item.placeholder"
                       :ref="el => createRef(item.name, el)"
                       :required="setRequired(myFormData, item)"
                       :rules="item.rules"
                       :type="item.type"
                       :value="myFormData[item.name]"
                       @blur="(_) => onBlur(item, myFormData[item.name])"
                       @change="(event) => onChange(item, (event.target as HTMLInputElement).value)"
                       @focus="(_) => onFocus(item, myFormData[item.name])"
                       @input="(event) => onInput(item, (event.target as HTMLInputElement).value)" />
                <div v-if="(item as IwFormInputText).clearable && myFormData[item.name]">
                  <span @click="onChange(item, '')">
                    <Icon icon="maki:cross-11"
                          class="iwFormClearable" />
                  </span>
                </div>
                <p v-if="showHelperText"
                   class="iwFormInputHelperText">
                  <template v-if="errors[item.name]">
                    <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                  </template>
                  <template v-else> {{ item.helperText }} </template>
                </p>
              </div>
            </template>

            <template v-else-if="item.type === 'number'">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <div class="iwFormInputContainer">
                <div v-if="item.showPrefixIcon"
                     class="iwFormInputPrependIcon">
                  <Icon class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        :icon="item.prefixIcon!"></Icon>
                </div>
                <IwFormInputNumber :aria-label="getAriaLabel(item)"
                                   :autocomplete="item.autocomplete ?? 'on'"
                                   :class="getInputCss(item)"
                                   :disabled="isDisabled(item.disabled, isReadOnly)"
                                   :id="getId(item)"
                                   :allowDecimal="item.allowDecimal"
                                   :key="keys[item.name]"
                                   :min="item.min"
                                   :max="item.max"
                                   :name="item.name"
                                   :placeholder="item.placeholder"
                                   :ref="el => createRef(item.name, el)"
                                   :required="setRequired(myFormData, item)"
                                   :rules="item.rules"
                                   :step="item.step"
                                   :type="item.type"
                                   :value="myFormData[item.name]"
                                   @blur="(_) => onBlur(item, myFormData[item.name])"
                                   @change="(value) => onChange(item, value)"
                                   @focus="(_) => onFocus(item, myFormData[item.name])"
                                   @input="(value) => onInput(item, value)" />
                <p v-if="showHelperText"
                   class="iwFormInputHelperText">
                  <template v-if="errors[item.name]">
                    <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                  </template>
                  <template v-else> {{ item.helperText }} </template>
                </p>
              </div>
            </template>

            <template name="phone"
                      v-else-if="'phone' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <div class="iwFormInputPhoneWrapper">
                <div v-if="item.showPrefixIcon"
                     class="iwFormInputPrependIcon">
                  <Icon class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        :icon="item.prefixIcon!"></Icon>
                </div>
                <IwFormInputPhone :aria-label="getAriaLabel(item)"
                                  :autocomplete="item.autocomplete ?? 'on'"
                                  :class="getInputCss(item)"
                                  :countryCodeList="item.allowedCountries"
                                  :disabled="isDisabled(item.disabled, isReadOnly)"
                                  :id="getId(item)"
                                  :initCountryCodeVal="item.countryCodeDataKey
                                    ? myFormData[item.countryCodeDataKey]
                                    : null"
                                  :initPhoneNumberVal="myFormData[item.name]"
                                  :key="keys[item.name]"
                                  :name="item.name"
                                  :placeholder="item.placeholder"
                                  :ref="el => createRef(item.name, el)"
                                  :required="setRequired(myFormData, item)"
                                  :rules="item.rules"
                                  :showCountryCode="item.showCountryCode"
                                  @blur="(_) => onBlur(item, myFormData[item.name])"
                                  @change="(value) => phoneInputOnChange(item, value)"
                                  @focus="(_) => onFocus(item, myFormData[item.name])"
                                  @init="(value) => phoneInputOnChange(item, value, true)"
                                  @input="(value) => phoneInputOnChange(item, value)" />
                <div v-if="item.clearable && myFormData[item.name]">
                  <span @click="(inputRefs[item.name] as InstanceType<typeof IwFormInputPhone>).clear()">
                    <Icon icon="maki:cross-11"
                          class="iwFormClearable" />
                  </span>
                </div>
              </div>
              <p v-if="showHelperText"
                 class="iwFormInputHelperText">
                <template v-if="errors[item.name]">
                  <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                </template>
                <template v-else> {{ item.helperText }} </template>
              </p>
            </template>

            <template name="radio"
                      v-else-if="'radio' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" /><br>
              <IwFormInputRadio :id="getId(item)"
                                :key="keys[item.name]"
                                :choice="item.choice"
                                :columnCount="item.columnCount"
                                :disabled="item.disabled"
                                :name="item.name"
                                :required="setRequired(myFormData, item)"
                                :ref="el => createRef(item.name, el)"
                                :value="myFormData[item.name]"
                                @change="(value) => onChange(item, value)" />
              <p v-if="showHelperText"
                 class="iwFormInputHelperText">
                <template v-if="errors[item.name]">
                  <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                </template>
                <template v-else> {{ item.helperText }} </template>
              </p>
            </template>

            <template name="select"
                      v-else-if="'select' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :key="keys[item.name]"
                           :item="item"
                           :myFormData="myFormData" />

              <template v-if='!isReadOnly'>
                <VueMultiSelect :config="item.selectConfig"
                                :disabled="item.disabled"
                                :id="getId(item)"
                                :key="keys[item.name]"
                                :ref="el => createRef(item.name, el)"
                                :attributes="{
                                  clearOnSelect: false,
                                  openDirection: 'bottom',
                                  preventAutofocus: false,
                                  required: setRequired(myFormData, item)
                                }"
                                @changed="(selectedKeys, selectedRaw, justSelected) =>
                                  selectInputOnChange(item, selectedKeys, selectedRaw, justSelected, myForm)"
                                @inited="(selectedKeys, selectedRaw, justSelected) =>
                                  selectInputOnInit(item, selectedKeys, selectedRaw, justSelected, myForm)"
                                @removed="(selectedKeys, selectedRaw, justRemoved) =>
                                  selectInputOnChange(item, selectedKeys, selectedRaw, justRemoved, myForm)"
                                @searchChanged="async (query) => {
                                  await onSelectSearch(item, query)
                                }" />
                <p v-if="showHelperText"
                   class="iwFormInputHelperText">
                  <template v-if="errors[item.name]">
                    <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                  </template>
                  <template v-else> {{ item.helperText }} </template>
                </p>
              </template>
              <template v-else>
                <input type="text"
                       :id="`${identifier}-${item.name}`"
                       v-model='myFormData[item.name]'
                       :label='item.label'
                       disable />
              </template>
            </template>

            <template name="checkbox"
                      v-else-if="'checkbox' === (item.type)">
              <input :id="getId(item)"
                     class="iwFormCheckbox"
                     type="checkbox"
                     :key="keys[item.name]"
                     v-model="myFormData[item.name]"
                     :disabled="item.disabled"
                     :name="item.name"
                     :ref="el => createRef(item.name, el)"
                     :true-value="item.checkBoxTrueValue ?? true"
                     :false-value="item.checkBoxFalseValue ?? false"
                     @change="(ev) => onChange(item, myFormData[item.name])">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
            </template>

            <template name="date"
                      v-else-if="'date' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <EasepickCalendar :id="getId(item)"
                                :disabled="item.disabled"
                                :key="keys[item.name]"
                                :options="item.dateOptions!"
                                :ref="el => createRef(item.name, el)"
                                @change="(val) => dateOnChange(item, val)"
                                @reset="inputOnReset(item)" />
              <p v-if="showHelperText"
                 class="iwFormInputHelperText">
                <template v-if="errors[item.name]">
                  <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                </template>
                <template v-else> {{ item.helperText }} </template>
              </p>
            </template>

            <template name="uploader"
                      v-else-if="'uploader' === item.type">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <IwFormUploader :id="getId(item)"
                              :config="item.config"
                              :disabled="item.disabled"
                              :key="keys[item.name]"
                              :ref="el => createRef(item.name, el)"
                              @change="(file?: File | string | null) => onChange(item, file)" />
            </template>

            <template name="switch"
                      v-else-if="'switch' === (item.type)">
              <IwFormInputSwitch :id="getId(item)"
                                 type="checkbox"
                                 :key="keys[item.name]"
                                 :disabled="item.disabled"
                                 :isEnabled="item.isEnabled"
                                 :name="item.name"
                                 :switchTrueValue="item.switchTrueValue"
                                 :switchFalseValue="item.switchFalseValue"
                                 :value="myFormData[item.name]"
                                 :ref="el => createRef(item.name, el)"
                                 @change="(value) => onChange(item, value)" />
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <p v-if="showHelperText"
                 class="iwFormInputHelperText">
                <template v-if="errors[item.name]">
                  <span class="iwFormInputErrorText">{{ errors[item.name] }}</span>
                </template>
                <template v-else> {{ item.helperText }} </template>
              </p>
            </template>

            <template name="editor"
                      v-else-if="'editor' === (item.type)">
              <IwFormLabel :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <Editor :config="item.config"
                      :formInput="item"
                      :id="getId(item)"
                      :key="keys[item.name]"
                      :funcToastError="props.funcToastError"
                      :ref="el => createRef(item.name, el)"
                      @change="(htmlData) => onChange(item, htmlData)" />
            </template>

            <template name="editor_textarea"
                      v-else-if="'editor_textarea' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <EditorTextArea :config="item.config"
                              :formInput="item"
                              :id="getId(item)"
                              :key="keys[item.name]"
                              :ref="el => createRef(item.name, el)"
                              @change="(htmlData) => onChange(item, htmlData)" />
            </template>

            <template name="component"
                      v-else-if="'component' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <component :is="item.component"
                         :formInput="item"
                         :formData="myFormData"
                         :key="keys[item.name]"
                         :ref="(el: typeof item.component) => createRef(item.name, el)"
                         v-bind="item.config"
                         @change="(data: any) => onChange(item, data)" />
            </template>

            <template name="range_slider"
                      v-else-if="'range_slider' === (item.type)">
              <IwFormLabel :id="getId(item)"
                           :item="item"
                           :key="keys[item.name]"
                           :myFormData="myFormData" />
              <IwFormInputRangeSlider :aria-label="getAriaLabel(item)"
                                      :id="getId(item)"
                                      :ref="el => createRef(item.name, el)"
                                      :initialValue="item.initialValue"
                                      :min="item.min"
                                      :max="item.max"
                                      :step="item.step"
                                      @change="(value: number) => onChange(item, value)" />
            </template>
          </div>
        </template><!-- end of form inputs -->
        <div :class="['iwFormSubmitBtnWrapper', group.submitBtn?.css ?? '']"
             v-if="group.showSubmitBtn">
          <IwFormBtn type="submit"
                     :disabled="!isSubmitBtnEnabled"
                     :isLoading="showSubmitLoading && submitIsLoading"
                     :label="getFormGroupSubmitLabel(group, totalSubmission > 0 ? formSubmitAgainText : submitText)" />
          <div :class="css.cssResetBtnWrapper ?? 'iwFormResetFilterBtnWrapper'">
            <button v-if="showResetBtn"
                    class="iwFormResetFilterBtn"
                    @click="formOnReset"
                    type="button">{{ resetText }}</button>
          </div>
        </div>
      </div><!-- end of form groups -->

      <div :class="css.cssSubmitBtnWrapper ?? 'iwFormInputWrapper'">
        <template v-if="showSubmitBtn">
          <slot name='submitBtn'>
            <div class="iwFormSubmitBtnWrapper">
              <IwFormBtn type="submit"
                         :disabled="!isSubmitBtnEnabled"
                         :isLoading="showSubmitLoading && submitIsLoading"
                         :label="`${totalSubmission > 0 ? formSubmitAgainText : submitText}`" />
              <div :class="css.cssResetBtnWrapper ?? 'iwFormResetFilterBtnWrapper'">
                <button v-if="showResetBtn"
                        class="iwFormResetFilterBtn"
                        @click="formOnReset"
                        type="button">{{ resetText }}</button>
              </div>
            </div>
          </slot>
        </template>
      </div>

    </form>

    <div v-if="formErrorMsg"
         class="iwFormAlertError"
         role="alert">
      <span>{{ formErrorMsg }}</span>
    </div>
  </div>
</template>
