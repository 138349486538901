//@unocss-include
export class IwFormUploaderConfig {
    public _files?: FileList | null
    /**
     * Will default to a set list of types, based on the {@link type} if not
     * specified
     */
    public acceptedFileTypes: string[] = []
    /**
     * Generate a custom preview image
     *
     * @param imgSrc The uploaded {@link imageSrc}.
     * @returns Custom preview in image URL form
     */
    public generateImgPreview?: (imgSrc: string) => (string | null | undefined)
    public icon: string = 'octicon:cloud-upload'
    /**
     * The uploaded image file source
     *
     * Possible format:
     * - URL: Returned by {@link uploadFunc} if defined
     * - [Data URL](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs):
     *   When the image is first uploaded to the uploader
     */
    public imageSrc?: string
    public isUploading = false
    public emit?: Function
    public label: string = 'Click to upload'
    public maxHeight: string = 'max-h-[500px]'
    public maxImageSizeInMb?: number
    public onDrop?: Function
    public onDragOver?: Function
    public onImageLoaded?: (src: string) => void
    public onLoad?: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null;
    public onError?: Function
    /** Function to handle file removal, return `true` to proceed to remove file */
    public onRemoveFile?: () => boolean
    public onUploaded?: (res: any) => any
    public onUploadFileChange?: Function
    public sampleDownloadLink?: string
    public sampleDownloadLinkLabel?: string
    public showDeleteBtn = true
    public type: IwFormUploadType = 'image'
    public uploadFunc?: (files?: FileList | null) => any | { url: string }

    constructor(options?: Partial<IwFormUploaderConfig>) {
        // Set default `acceptedFileTypes` if not specified
        if (!options || options.acceptedFileTypes == null) {
            switch (options?.type || this.type) {
                case 'csv':
                    this.acceptedFileTypes = [...uploaderFileCategories.csv]
                    break
                case 'image':
                    this.acceptedFileTypes = [...uploaderFileCategories.image]
                    break
            }
        }

        Object.assign(this, options)
    }

    public async _onDrop(event: DragEvent) {
        const files = event.dataTransfer?.files;
        this._onFileChanged(files)

        return
    }

    private _onFileChanged(files?: FileList | null) {
        this._files = files;
        if (files && files.length > 0) {
            const file = files[0];
            const filesizeInMiB = Math.floor((file.size / 1024) / 1024)

            if (this.acceptedFileTypes.length > 0 &&
                !this.acceptedFileTypes.includes(file.type)) {
                throw new Error(`Only file ` + this.acceptedFileTypes.join(', ') + ` are allowed.`)
            }

            // Check file size constraint
            if (this.maxImageSizeInMb != null && filesizeInMiB > this.maxImageSizeInMb) {
                throw new Error(`File should be less than ${this.maxImageSizeInMb}MiB in size.`)
            }

            const reader = new FileReader()
            reader.onload = this.onLoad ?? this._onLoad.bind(this)
            reader.readAsDataURL(file);
        }
    }


    public async _onLoad(e: ProgressEvent<FileReader>) {
        const result = e.target?.result
        if (result) {
            if ('image' == this.type) {
                if (this.onImageLoaded) this.onImageLoaded(result as string)

            }

            if (this.uploadFunc) {
                this.isUploading = true
                const res = await this.uploadFunc(this._files) as any
                this.isUploading = false
                if (this.onUploaded) this.onUploaded(res)
            }
        }

    }

    public _onUploadFileChange(event: Event) {
        const inputElement = event.target as HTMLInputElement
        this._onFileChanged(inputElement.files)
    }

    public _clearFileList() {
        this._files = null
    }

    public getAcceptedFileTypes(): string | undefined {
        if (this.acceptedFileTypes) {
            return this.acceptedFileTypes.join(',')
        }

        return undefined
    }

    public getFiles(): FileList | null | undefined {
        return this._files
    }
}

export default IwFormUploaderConfig

/**
 * Store all possible MIME types and file extensions for each file type
 *
 * For use in `<input type='file' />`
 *
 * See {@link uploaderFileCategories} for simpler usage
 *
 * ### References
 * - CSV: https://stackoverflow.com/a/42140178
 * - Images:https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
 */
export const uploaderFileTypes = {
    apng: [
        'image/apng',
        '.apng',
    ],
    av1: [
        'image/avif',
        '.avif',
    ],
    bmp: [
        'image/bmp',
        '.bmp',
    ],
    csv: [
        'application/csv',
        'application/vnd.ms-excel',
        'application/x-csv',
        'text/comma-separated-values',
        'text/csv',
        'text/plain',
        'text/x-comma-separated-values',
        'text/x-csv',
        '.csv',
    ],
    gif: [
        'image/gif',
        '.gif',
    ],
    ico: [
        'image/x-icon',
        '.cur',
        '.ico',
    ],
    jpeg: [
        'image/jpeg',
        '.jpg',
        '.jpeg',
        '.jfif',
        '.pjpeg',
        '.pjp',
    ],
    pdf: [
        'application/pdf',
        '.pdf',
    ],
    png: [
        'image/png',
        '.png',
    ],
    svg: [
        'image/svg+xml',
        '.svg',
    ],
    tiff: [
        'image/tiff',
        '.tif',
        '.tiff',
    ],
    tsv: [
        'application/vnd.ms-excel',
        'text/plain',
        'text/tab-separated-values',
        '.tab',
        '.tsv',
    ],
    webp: [
        'image/webp',
        '.webp',
    ],
} as const

/**
 * Store all possible MIME types and file extensions for each file category
 *
 * For use in `<input type='file' />`
 *
 * See {@link uploaderFileTypes} for fine-tuned file type selection
 */
export const uploaderFileCategories = {
    csv: [...uploaderFileTypes.csv, ...uploaderFileTypes.tsv],
    image: [
        ...uploaderFileTypes.apng,
        ...uploaderFileTypes.av1,
        ...uploaderFileTypes.bmp,
        ...uploaderFileTypes.gif,
        ...uploaderFileTypes.ico,
        ...uploaderFileTypes.jpeg,
        ...uploaderFileTypes.png,
        ...uploaderFileTypes.svg,
        ...uploaderFileTypes.tiff,
        ...uploaderFileTypes.webp,
    ],
    pdf: [...uploaderFileTypes.pdf],
} as const
