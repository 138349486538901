<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { Tippy } from 'vue-tippy'

defineOptions({
    // Disallow HTML attributes fallthrough to top-level element, instead
    // intercept and insert into internal input element.
    inheritAttrs: false,
})

////////////////////////////////////////////////////// @ Props & Emits
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    allowDecimal: {
        type: Boolean,
        default: false,
    },
    min: {
        type: Number,
        default: Number.NEGATIVE_INFINITY,
    },
    max: {
        type: Number,
        default: Number.POSITIVE_INFINITY,
    },
    placeholder: {
        type: String,
    },
    step: {
        type: Number,
        default: 1,
    },
    disabled: {
        type: Boolean,
    }
})

const emit = defineEmits<{
    (e: 'blur', ev: Event): void,
    (e: 'change', val: number): void,
    (e: 'focus', ev: Event): void,
    (e: 'input', val: number): void,
}>()

////////////////////////////////////////////////////////// @ Variables
//////////////////////////////////////////////////////////////////////
const allowDecimal = ref(props.allowDecimal)
const numberInputData = ref<number | null>()
const inputRef = ref<HTMLInputElement>()
const tippyRef = ref()
const tippyContent = ref<string>()

////////////////////////////////////////////////////////// @ Computed
//////////////////////////////////////////////////////////////////////
const placeholder = computed(() => {
    if (props.max === Number.POSITIVE_INFINITY) {
        return props.placeholder ? props.placeholder : "Enter a number"
    }
    return props.placeholder ? props.placeholder : `Enter a number up to ${props.max}`
})

////////////////////////////////////////////////////////// @ Functions
//////////////////////////////////////////////////////////////////////
/**
 * Clamps the value to be within min and max, also check if input type is Number
 *
 */
function clamp(value: any): number {
    onInvalidInput(value)
    if (typeof value !== 'number') {
        return props.min
    }

    if (!allowDecimal.value) {
        value = Math.round(value)
    }

    if (value < props.min) {
        return props.min
    }

    if (value > props.max) {
        return props.max
    }

    return value
}

/**
 * Event handler for change in number input
 */
function onChange() {
    if (numberInputData.value != null) {
        numberInputData.value = clamp(numberInputData.value)
        emit('change', numberInputData.value)
    }
}

/**
 * Event handler for every keystroke of number input
 */
function onInput() {
    if (numberInputData.value != null) {
        emit('input', numberInputData.value)
    }
}

function onInvalidInput(value?: any) {
    if (inputRef.value) {
        inputRef.value.focus()
    }
    if (tippyRef.value && !props.disabled) {
        if (typeof value === 'number') {
            if (value < props.min && props.min != Number.NEGATIVE_INFINITY) {
                tippyContent.value = `Value must be at least ${props.min}`
            } else if (value > props.max && props.max != Number.POSITIVE_INFINITY) {
                tippyContent.value = `Value must be at most ${props.max}`
            } else if (!allowDecimal.value && !Number.isInteger(value)) {
                tippyContent.value = `Value must be an integer`
            } else {
                return
            }
        } else {
            tippyContent.value = 'Invalid input'
        }
        tippyRef.value.show()
    }
}

////////////////////////////////////////////////////////// @ Lifecycle
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////
if (props.step % 1 != 0) {
    allowDecimal.value = true
}

</script>

<template>
    <tippy ref="tippyRef"
           :tabindex="disabled ? null : '0'"
           trigger="manual"
           @focus="onInvalidInput(numberInputData)">
        <template #default>
            <input v-bind="$attrs"
                   :disabled="disabled"
                   tabindex="0"
                   ref="inputRef"
                   type="number"
                   :placeholder="disabled ? '' : placeholder"
                   v-model="numberInputData"
                   :min="props.min"
                   :max="props.max"
                   :step="props.step"
                   @blur="emit('blur', $event)"
                   @change="onChange"
                   @focus="emit('focus', $event)"
                   @input="onInput" />
        </template>

        <template #content>
            {{ tippyContent }}
        </template>
    </tippy>
</template>

<style>
/* Hide Arrow box on Firefox */
input[type=number]:disabled {
    appearance: textfield;
    -moz-appearance: textfield;
}
</style>
