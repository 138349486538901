////////////////////////////////////////////////////////////@  Imports
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/**
 * Define a list of actions that are available
 *
 * NOTE: Do not use these actions directly, instead create an instance of
 * {@link IwFormHtmlAction} and use the instance methods (E.g. `installAction()`)
 * provided.
 */
export const mainActionList = {
    'btn': {
        'external-url': {
            generateDataAttrs(link: string, target?: string) {
                return {
                    'data-btn-external-url': link,
                    'data-btn-external-url-target': target,
                }
            },
            install(elem, data) {
                const url = data.btnExternalUrl as string | null
                const target = (data.btnExternalUrlTarget ?? '_blank') as string
                if (!url) return

                return {
                    click: () => {
                        window.open(url, target)
                    },
                }
            },
        } satisfies ActionConfig<
            ['data-btn-external-url', 'data-btn-external-url-target'],
            [link: string, target: string]
        >,
    },
    'link': {
        'external-url': {
            generateDataAttrs(link: string) {
                return {
                    'data-link-external-url': link,
                }
            },
            install(elem, data) {
                const url = data.linkExternalUrl as string | null
                if (url) {
                    elem.setAttribute('href', url)
                }
            },
        },
    },
} as const satisfies ActionGroup

///////////////////////////////////////////////////////////////@ Types
//////////////////////////////////////////////////////////////////////

export type ActionConfig<
    DataT extends Array<string> = Array<string>,
    GenerateAttrParamT extends Array<any> = Array<any>,
> = {
    /**
     * Process the passed-in data to return in the form of [data attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/data-*)
     *
     * ### Example
     * ```ts
     * // An example to clamp number before returning 'data-*' attributes
     * {
     *   generateDataAttrs(value: number, max: number, min: number) {
     *     return {
     *       'data-num': Math.max(Math.min(value, max), min),
     *     }
     *   },
     * }
     * ```
     */
    generateDataAttrs?: ((
        ...args: GenerateAttrParamT
    ) => Partial<Record<DataT[number], HtmlAttrType | null>>) | null
    /**
     * Callback that will run on initialization
     *
     * ### Example
     * ```ts
     * // Example of an element that can be toggled on by click or hover.
     * {
     *   install(elem, data) {
     *     const isShownOnHover = data.showOnHover
     *     let isShownWithoutHover = false
     *
     *     const show = () => elem.style.opacity = '1'
     *     const hide = () => elem.style.opacity = '0'
     *
     *     return {
     *       click: () => {
     *         isShownWithoutHover = !isShownWithoutHover
     *         isShownWithoutHover ? show() : hide()
     *       },
     *       mouseover() {
     *         if(isShownOnHover && !isShownWithoutHover) show()
     *       },
     *       mouseout: {
     *         callback() {
     *           if(!isShownWithoutHover) hide()
     *         },
     *       },
     *     }
     *   },
     * }
     * ```
     */
    install: (
        // Disallow the usage of '[add|...]EventListener()` and 'on[Click|...]()'
        e: Omit<HTMLElement, `${string}EventListener` | `on${string}`>,
        data: Record<
            DatasetKeyToObjProp<DataT[number]>,
            HtmlAttrType | null | undefined
        >,
    ) => MaybePromise<{
        [key in keyof HTMLElementEventMap]?: EventCallbackFunc<key> | {
            /**
             * Callback function to be executed when the event is triggered
             *
             * ### Example
             * ```ts
             * {
             *   install() {
             *     return {
             *       click: {
             *         callback: () => console.log('Clicked'),
             *       },
             *     }
             *   },
             * }
             * ```
             */
            callback: EventCallbackFunc<key>
            /**
             * Target element that the event listener will apply to
             *
             * Default to the current element that `install()` targets.
             */
            element?: HTMLElement
            /**
             * Additional event listener options
             */
            options?: AddEventListenerOptions | null
        } | null
    } | undefined | null> | void
    /**
     * To run the {@link ActionConfig.install} based on the value of `order`
     *
     * The action with the lowest value of `order` will be executed first.
     *
     * All actions have the default order value of `0`.
     */
    order?: number | null
}

export type ActionGroup = {
    [K: string]: ActionConfig | ActionGroup
}

type ActionPathDotNotation = string

/** Convert `data-attr-name` to `attrName` */
type DatasetKeyToObjProp<T extends string> = T extends `data-${infer Key}`
    ? CamelCaseFromKebabCase<Key>
    : string

type EventCallbackFunc<
    T extends keyof HTMLElementEventMap = keyof HTMLElementEventMap,
> = (e: HTMLElementEventMap[T]) => any

/** Possible value type of non-nullable HTML attributes */
type HtmlAttrType = string

/** Helper to make a type possibly `Promise` */
type MaybePromise<T> = Promise<T> | T

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Provide DOM elements with custom actions, to run with extra capabilities
 *
 * ### Example
 * ```ts
 * // Init:
 * const actions = new IwFormHtmlAction()
 *
 * // During mount:
 * // See `installAction()` for more information
 * actions.installAction('button')
 *
 * // During unmount:
 * actions.uninstallAction()
 * ```
 */
export class IwFormHtmlAction {
    #activeEventListeners: Array<{
        element: HTMLElement
        callback: EventCallbackFunc
        options: AddEventListenerOptions | null | undefined
        type: keyof HTMLElementEventMap
    }>
    #internalActionList: ActionGroup = mainActionList

    constructor(customActionList?: ActionGroup) {
        this.#activeEventListeners = []

        if (customActionList) {
            this.#internalActionList = customActionList
        }
    }

    /**
     * Generate an object of `'data-*'` attributes
     *
     * ### Example
     * ```ts
     * // Basic example:
     * // Based on an action's `generateDataAttrs`.
     * // (In this case, `generateDataAttrs` in 'tooltip.textColor')
     * generateDataAttrsFromDefaultList('tooltip.textColor', '#fff')
     * // returns: { 'data-action-XXXX': 'tooltip.color', 'data-tooltip-color': '#fff' }
     * ```
     *
     * See {@link generateDataAttrs} instance method to use custom config
     * with {@link IwFormHtmlAction}
     */
    static generateDataAttrsFromDefaultList<T extends ActionConfig>(
        actionPath: ActionPathDotNotation,
        ...args: Parameters<NonNullable<T['generateDataAttrs']>>
    ): Record<string, HtmlAttrType | null | undefined> {
        const actionConfig = IwFormHtmlAction.getActionConfig(
            actionPath,
            mainActionList,
        )

        const dataActionKey = `data-action-${IwFormHtmlAction.generateRandomId()}`
        return {
            [dataActionKey]: actionPath,
            ...actionConfig?.generateDataAttrs?.apply(null, args),
        }
    }

    /**
     * Generate an object of `'data-*'` attributes
     *
     * ### Example
     * ```ts
     * // Basic example:
     * // Based on an action's `generateDataAttrs`.
     * // (In this case, `generateDataAttrs` in 'tooltip.textColor')
     * generateDataAttrs('tooltip.textColor', '#fff')
     * // returns: { 'data-action-XXXX': 'tooltip.color', 'data-tooltip-color': '#fff' }
     * ```
     */
    generateDataAttrs<T extends ActionConfig>(
        actionPath: ActionPathDotNotation,
        ...args: Parameters<NonNullable<T['generateDataAttrs']>>
    ): Record<string, HtmlAttrType | null | undefined> {
        const actionConfig = IwFormHtmlAction.getActionConfig(
            actionPath,
            this.#internalActionList,
        )

        const dataActionKey = `data-action-${IwFormHtmlAction.generateRandomId()}`
        return {
            [dataActionKey]: actionPath,
            ...actionConfig?.generateDataAttrs?.apply(null, args),
        }
    }

    /**
     * Generate random ID for `data-action-XXXX` use
     */
    static generateRandomId(): string {
        return Math.round(Math.random() * Number.MAX_SAFE_INTEGER).toString()
    }

    /**
     * Retrieve internal action config by the dot notation given
     *
     * ### Example
     * ```ts
     * getActionConfig('tooltip.hover')
     * // returns: {
     * //   install: ...,
     * //   options: ...,
     * //   ...
     * // }
     * ```
     */
    static getActionConfig(
        actionPath: ActionPathDotNotation,
        actionList: ActionGroup = mainActionList,
    ): ActionConfig | null {
        const actionTree = actionPath?.split('.') ?? []

        let actionData: ActionGroup | ActionConfig | undefined = actionList
        for (const pathName of actionTree) {
            if ('install' in actionData && typeof actionData === 'function') {
                break
            }
            actionData = actionData?.[pathName]
        }

        return actionData && 'install' in actionData
            ? actionData as ActionConfig
            : null
    }

    /**
     * Get processed data from the TipTap Node/Mark's dataset
     *
     * ### Example
     * ```ts
     * // Example usage in TipTap's `addAttributes()` config
     * {
     *   color: {
     *     parseHTML: element => {
     *       const data = IwFormHtmlAction.getEditorParsedDataFromDataset(
     *         'tooltip.textColor',
     *         element.dataset,
     *       )
     *       return data.color
     *     }
     *   },
     * }
     * ```
     */
    static getEditorParsedDataFromDataset(
        actionPath: ActionPathDotNotation,
        dataset: DOMStringMap,
        actionList: ActionGroup = mainActionList,
    ): Record<DatasetKeyToObjProp<string>, string | undefined> {
        return dataset
    }

    /**
     * Merge multiple generated data attributes together
     *
     * ### Example
     * ```ts
     * mergeDataAttributes(
     *   generateDataAttrs('tooltip.hover'),
     *   generateDataAttrs('tooltip.color', '#fff'),
     * )
     * // returns: {
     * //   'data-action-XXXX': `tooltip.hover`,
     * //   'data-action-YYYY': `tooltip.color`,
     * //   ...
     * // }
     * ```
     */
    static mergeDataAttributes(
        ...attrs: Array<Record<string, HtmlAttrType>>
    ): Record<string, HtmlAttrType> {
        const data: Record<string, HtmlAttrType> = {}
        const actionsLookup = new Set<string>()

        for (const obj of attrs) {
            for (let key in obj) {
                if (key.startsWith('data-action')) {
                    if (actionsLookup.has(obj[key])) continue
                    // Store all actions (E.g. 'tooltip.color') for duplicates
                    // checking
                    actionsLookup.add(obj[key])

                    while (key in data) {
                        // Keep randomizing `data-action` identifier until no
                        // duplicate
                        const id = IwFormHtmlAction.generateRandomId()
                        key = `data-action-${id}`
                    }
                }
                data[key] = obj[key]
            }
        }

        return data
    }

    /**
     * Install action to the list of element provided, based on the elements'
     * `data-action` value
     *
     * ### Example
     * ```ts
     * // Apply to a select list of elements
     * const tooltip = document.querySelectorAll('.tooltip')
     * installAction(tooltip)
     *
     * // Apply to a list of elements with CSS selector
     * installAction('.tooltip')
     *
     * // Apply to all applicable elements in a container
     * installAction('#container *')
     * ```
     */
    async installAction(elementList: NodeList | Array<Element | HTMLElement> | string) {
        if (!document) {
            console.warn('[installAction] `document` is not defined')
            return
        }

        if (typeof elementList === 'string') {
            elementList = document.querySelectorAll(elementList)
        }
        if (!elementList.length) return

        // Set event listener to each element in element list.
        for (const elem of elementList as Array<HTMLElement>) {
            // Parse stored 'action' from element's dataset
            const actionStrList = Object.entries(elem.dataset)
                .filter(([key, value]) => key.startsWith('action'))
                .map(([key, value]) => value)

            // Load all actions' config
            const actions = actionStrList
                .map(act => {
                    if (!act) return

                    const data = IwFormHtmlAction.getActionConfig(
                        act,
                        this.#internalActionList,
                    )

                    if (!data) {
                        console.warn(`[installAction] Action with no effect. ('${act}')`)
                    }
                    return data
                })
                // Remove invalid actions
                .filter(act => !!act)

            // Sort each actions by their `order`
            actions.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))

            // Process each 'actions'
            for (const actionData of actions) {
                const events = await actionData.install(elem, elem.dataset)
                if (!events) continue

                // Set event listener(s) and store it for removal later.
                Object.entries(events).forEach(([evType, ev]) => {
                    const type = evType as keyof HTMLElementEventMap
                    let callback: EventCallbackFunc
                    let options: AddEventListenerOptions | null | undefined
                    let target = elem

                    // Parse event listener config or function
                    if (typeof ev === 'object' && ev && ev.callback) {
                        callback = ev.callback as EventCallbackFunc
                        options = ev.options
                        if (ev.element) {
                            target = ev.element
                        }
                    } else if (typeof ev === 'function') {
                        callback = ev as EventCallbackFunc
                    } else {
                        return
                    }

                    // Set event listener to element
                    target.addEventListener(
                        type,
                        callback,
                        options ?? undefined
                    )
                    // Store event listener config
                    this.#activeEventListeners.push({
                        element: target,
                        callback,
                        options,
                        type,
                    })
                })
            }
        }
    }

    /**
     * Retrieve and remove previously stored event listener
     *
     * Any event listeners set by {@link installAction} for this instance will
     * be automatically removed.
     *
     * ### Example
     * ```ts
     * // During mount
     * const instance = new IwFormHtmlAction()
     * instance.installAction('.tooltip')
     * instance.installAction(document.querySelectorAll('.btn'))
     *
     * // During unmount
     * // Remove event listeners for all elements that matches 'tooltip' and
     * // 'btn' class (that was set previously).
     * instance.uninstallAction()
     * ```
     */
    uninstallAction() {
        this.#activeEventListeners
            .splice(0, this.#activeEventListeners.length)
            .forEach(listener => {
                listener.element.removeEventListener(
                    listener.type,
                    listener.callback,
                    listener.options ?? undefined,
                )
            })
    }
}

export default IwFormHtmlAction
