<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import IwFormRule from '../../../utils/IwFormRule'
import IwFormConfig from '../../../utils/IwFormConfig'
import IwFormUploaderConfig, { uploaderFileTypes } from '../../../utils/IwFormUploaderConfig'
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////
/**
 * insert() is called when the insert button is clicked
 */
const emit = defineEmits(['insert', 'edit'])

const props = defineProps({
    maxImageSizeInMb: {
        type: Number,
    },
})

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const imageConfig = ref({
    src: '',
    widthPercentage: '',
})
const imageUploadConfig = ref<IwFormUploaderConfig>()
const imageWidthPercentageDefaultValue = 60
const imageWidthPercentage = ref(imageWidthPercentageDefaultValue)
/** Dictates whether an image has been uploaded (not removed) on the file input */
const isImageUploaded = ref(false)
const key = ref()
/** Show or hide the modal */
const showInsertImageModal = ref(false)
const showDeleteBtn = ref(true)

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
// TODO: Validate image pixel size in this component also, currently
//       handled in EditorTipTap
// TODO: Only enable iwFormSubmitBtn on image insert
// TODO: Load image size
const form = computed(() => {
    return new IwFormConfig({
        formGroups: [{
            css: 'grid grid-cols-1 gap-4',
            formInputs: [
                {
                    type: 'range_slider',
                    name: 'imageResize',
                    label: 'Adjust Image Width%',
                    min: 20,
                    max: 100,
                    initialValue: imageWidthPercentage.value,
                    step: 5,
                    tooltip: 'Based on the width of the article',
                    isVisible: () => isImageUploaded.value,
                },
                {
                    type: 'uploader',
                    name: 'imageUpload',
                    label: 'Image Preview',
                    tooltip: `File larger than ${props.maxImageSizeInMb}MB would be compressed`,
                    cssWrapper: 'col-span-full',
                    config: updateImageUploadConfig(imageConfig.value.src),
                },
            ]
        }]
    })
})

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////
/**
 * Hide the image configuration modal
 */
function hideModal() {
    showInsertImageModal.value = false
    if (!imageConfig.value.src) {
        isImageUploaded.value = false
    }
    imageWidthPercentage.value = imageWidthPercentageDefaultValue
    imageUploadConfig.value?._clearFileList()
}

function loadModalOnImageSelect(attrs: typeof imageConfig.value) {
    isImageUploaded.value = true
    imageConfig.value.src = attrs.src
    imageWidthPercentage.value = Number.parseFloat(attrs.widthPercentage)
    showDeleteBtn.value = false
    updateKey()
}

function onEditImage() {
    emit('edit', imageConfig.value.src, `${imageWidthPercentage.value}%`)
    hideModal()
}

/**
 * Handle the submit event of the form
 */
async function onImageConfigFormSubmit(data: Record<string, any>) {
    const file = data.imageUpload

    if (!file) {
        throw new Error('No image provided.')
    }

    const imageFileUrl = window.URL.createObjectURL(file)
    const tempImage = new Image()
    tempImage.src = imageFileUrl

    await new Promise((resolve, reject) => {
        tempImage.onload = () => {
            window.URL.revokeObjectURL(imageFileUrl)

            // Get the original image size
            // const origWidth = tempImage.naturalWidth
            // const origHeight = tempImage.naturalHeight
            // const ratio = origWidth / origHeight

            emit('insert', file, imageWidthPercentage.value, null)

            hideModal()
            resolve(null)
        }

        tempImage.onerror = () => {
            window.URL.revokeObjectURL(imageFileUrl)

            reject(new Error('Invalid image'))
        }
    })
}

function onUpdateRangeSliderValue(value: number) {
    imageWidthPercentage.value = value
}

function resetConfig() {
    isImageUploaded.value = false
    imageConfig.value.src = ''
    imageConfig.value.widthPercentage = ''
    imageWidthPercentage.value = imageWidthPercentageDefaultValue
    showDeleteBtn.value = true
}

/**
 * Show the image configuration modal
 */
function showModal() {
    showInsertImageModal.value = true
}

/**
 * Toggle to show or hide the image configuration modal
 */
function toggleModal() {
    showInsertImageModal.value = !showInsertImageModal.value
}

function unloadModalOnImageUnselect() {
    resetConfig()
}

function updateImageUploadConfig(defaultImageSrc?: string): IwFormUploaderConfig {
    imageUploadConfig.value = new IwFormUploaderConfig({
        acceptedFileTypes: [
            ...uploaderFileTypes.av1,
            ...uploaderFileTypes.apng,
            ...uploaderFileTypes.jpeg,
            ...uploaderFileTypes.gif,
            ...uploaderFileTypes.png,
            ...uploaderFileTypes.svg,
            ...uploaderFileTypes.webp,
        ],
        imageSrc: defaultImageSrc,
        onImageLoaded: () => isImageUploaded.value = true,
        onRemoveFile() {
            isImageUploaded.value = false
            return true
        },
        showDeleteBtn: showDeleteBtn.value
    })
    return imageUploadConfig.value
}

function updateKey() {
    key.value = (new Date()).getTime() + Math.random() * 10000
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
defineExpose({
    hide: hideModal,
    load: loadModalOnImageSelect,
    show: showModal,
    toggle: toggleModal,
    unload: unloadModalOnImageUnselect,
})

</script>

<template>
    <SimpleModal :isVisible="showInsertImageModal"
                 :showFooter="false"
                 :title="imageConfig.src ? 'Edit image ' : 'Insert Image'"
                 @close="hideModal">

        <div class="iwFormEditorModalContent">
            <IwForm class="iwFormEditorImageInsertUpload"
                    :isModified="isImageUploaded"
                    :key="key"
                    :myForm="form"
                    :submitText="imageConfig.src ? 'Update' : 'Insert'"
                    @dataUpdated="(formData: Record<string, any>) => onUpdateRangeSliderValue(formData.imageResize)"
                    :onSubmit="imageConfig.src ? onEditImage : onImageConfigFormSubmit" />
        </div>
    </SimpleModal>
</template>
