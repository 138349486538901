<script setup lang='ts'>
import { type PropType, computed, isReactive, reactive, ref, toRef, toRefs } from 'vue'
import IwFormInputSelectConfig from '../utils/IwFormInputSelectConfig';
import { Icon } from '@iconify/vue'
import VueMultiselect from 'vue-multiselect';
import { directive as vTippy } from 'vue-tippy'
import useVueMultiSelect from '../composables/useVueMultiSelect';

///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////


///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////
const emit = defineEmits(['changed', 'inited', 'removed', 'searchChanged'])

const props = defineProps({
    attributes: {
        type: Object as PropType<IwFormInputSelectConfigAttr>,
        default: {},
    },
    config: {
        type: Object as PropType<IwFormInputSelectConfig>,
        required: true,
    },
    disabled: { type: Boolean, default: false },
    id: {
        type: String,
    },
})

/**
 * Merge multiple configs to a single configuration object
 *
 * NOTE: The config defined in IwForm will replace the passed-in config from
 *       `IwFormConfig`.
 */
const config = reactive(Object.assign(
    {},
    isReactive(props.config) ? toRefs(props.config) : props.config,
    isReactive(props.attributes) ? toRefs(props.attributes) : props.attributes,
))

const selectRef = ref<VueMultiselect>()

const {
    // variables
    isUserTyping,
    keyName,
    selectedOption,

    // functions
    getLabelBy,
    getSelectedKeys,
    getTrackBy,
    updateSelected,
    onInput,
    onReset: onResetInternal,
} = useVueMultiSelect(config)


const options = toRef(config, 'options')
const showLoading = toRef(config, 'showLoading')
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

/** Whether or not to show a 'Reset' button for clearing the selected option */
const showResetButton = computed(() =>
    config.showResetButton
    && config.allowEmptySelect
    && !props.disabled
    && selectedOption.value !== null
    && selectedOption.value !== undefined
    && selectedOption.value[config.keyName] !== ''
)

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

function debounce(func: Function, delay: number) {
    let timeout: any
    return function (this: unknown, ...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), delay);
    }
}

async function init() {
    if (config.onInit) {
        await runAsync(config.onInit)
    } else if (config.selected != null) {
        updateSelected(config.selected)
    }

    // Emit current `selectedOption` even if `null`, when `init()` is triggered
    // on component re-render
    emit('inited', getSelectedKeys(), selectedOption.value, selectedOption.value)
}

function onClickReset() {
    // Reset selected option
    onResetInternal()

    // Emit select change
    onRemove(
        Array.isArray(selectedOption.value)
            ? selectedOption.value[0]
            : selectedOption.value!,
        null
    )
}

const onSearchChange = debounce(async (query: string) => {
    // Set typing status to `false` when `onChange` is triggered (typing stopped)
    isUserTyping.value = false

    if (config.onSearchChange) {
        await runAsync(config.onSearchChange, [query])
        emit('changed', getSelectedKeys(), selectedOption.value, selectedOption.value)
    }
    emit('searchChanged', query)
}, 1000)

function onSelect(selected: IwFormInputSelectOption, id: any) {
    emit('changed', getSelectedKeys(), selectedOption.value, selected)
}

function onRemove(removed: IwFormInputSelectOption, id: any) {
    emit('removed', getSelectedKeys(), selectedOption.value, removed)
}

function onReset() {
    const removed = selectedOption.value
    onResetInternal()
    emit('removed', getSelectedKeys(), selectedOption.value, removed)
}

async function runAsync(callback: (...args: any) => Promise<IwFormSelectCallBackResponse>, args?: Array<any>) {
    try {
        showLoading.value = true
        const res = await callback.apply(null, args ?? [])
        options.value.splice(0, options.value.length, ...res.options)

        const selected = res.selected != null ? res.selected : config.selected

        if (selected != null) {
            // Update selected option again even if `res.selected == null`,
            // in case the option list has changed (`config.selected` outdated)
            updateSelected(selected)
        }
    } catch (e) {
        console.warn(e)
        throw e
    } finally {
        showLoading.value = false
    }
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

init()

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
defineExpose({
    activate: computed(() => selectRef.value?.activate),
    deactivate: computed(() => selectRef.value?.deactivate),
    onReset,
})
</script>

<template>
    <VueMultiselect v-model="selectedOption"
                    :class="{ required: props.attributes.required }"
                    @select="onSelect"
                    @remove="onRemove"
                    @input="onInput"
                    @searchChange="onSearchChange"
                    :label="getLabelBy(config)"
                    :track-by="getTrackBy(config)"
                    :allowEmpty="config.allowEmptySelect"
                    :closeOnSelect="config.closeOnSelect"
                    :clearOnSelect="config.clearOnSelect"
                    :disabled="props.disabled"
                    :deselectLabel="config.allowEmptySelect ? undefined : ''"
                    :hideOnSelect="config.hideOnSelect"
                    :id="id"
                    :loading="showLoading"
                    :openDirection="config.openDirection"
                    :options="options"
                    :optionsLimit="config.optionsLimit"
                    :placeholder="config.placeholder"
                    :preserveSearch="config.preserveSearch"
                    :preventAutofocus="config.preventAutofocus ?? !config.autofocus"
                    :max="config.maxSelected"
                    :multiple="config.multiple"
                    ref="selectRef"
                    :searchable="config.searchable"
                    :selectLabel="config.selectLabel"
                    :showNoResults="config.showNoResults"
                    :taggable="config.taggable"
                    :tagPlaceholder="config.tagPlaceholder">
        <template v-if="config.searchLoadingText && isUserTyping"
                  #noResult>
            <p> {{ config.searchLoadingText }} </p>
        </template>
        <template v-else-if="config.searchFailedText"
                  #noResult>
            <p> {{ config.searchFailedText }} </p>
        </template>
        <template #singleLabel="{ option: selectedOption }">
            <div class="iwFormInputSelectSingle">
                <span>
                    {{ selectedOption[config.labelName] }}
                </span>
                <!-- NOTE: using `@mousedown` to override Multiselect's `mousedown` -->
                <Icon v-if="showResetButton"
                      class="iwFormInputSelectResetIcon"
                      icon="material-symbols:close-rounded"
                      v-tippy="'Reset Selected'"
                      @click="onClickReset()"
                      @mousedown.stop.prevent="" />
            </div>
        </template>
    </VueMultiselect>
</template>
