import revive_payload_client_VogQpPtUFI from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_2k80aL9RLL from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KtATsbPqEw from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_7zNmuBaWmG from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/.nuxt/components.plugin.mjs";
import unhead_g7g6Yk0sdX from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_8B6CZrormO from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_B86mNmBton from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_FRmGFsEaPh from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/.nuxt/plugin.mjs";
import plugin_uQjuP04Tt1 from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt-sanctum-auth@0.4.7/node_modules/nuxt-sanctum-auth/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/.nuxt/unocss.mjs";
import chunk_reload_client_zsX7ICcN4o from "/Users/kenng/public_html/vilor/vilor-sysref/vilor-sysref-admin/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.17.5_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_VogQpPtUFI,
  router_2k80aL9RLL,
  payload_client_KtATsbPqEw,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_g7g6Yk0sdX,
  prefetch_client_8B6CZrormO,
  plugin_client_B86mNmBton,
  plugin_FRmGFsEaPh,
  plugin_uQjuP04Tt1,
  unocss_MzCDxu9LMj,
  chunk_reload_client_zsX7ICcN4o
]