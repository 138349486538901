<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

const nuxtApp = useNuxtApp()
const progressBarStore = useProgressBarStore()

//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

const props = defineProps({
    /** The colour gradient from left to right */
    colors: {
        type: Array as PropType<Array<String>>,
        default: ['rgb(0, 220, 130)', 'rgb(52, 205, 254)', 'rgb(0, 71, 225)'],
    },
    /** The base duration for the loader to stay loading */
    duration: {
        type: Number,
        default: 3000,
    },
    /**
     * The duration that the progress bar will take to hide itself when
     * `finish()` is called
     */
    durationToHide: {
        type: Number,
        default: 300,
    },
    /** The height of the progress bar */
    heightWithUnit: {
        type: String,
        default: '2px',
    },
    /** Listen to Nuxt hooks for page start and finish events */
    nuxtHookEnabled: {
        type: Boolean,
        default: true,
    },
})

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////

/** Ref to the progress bar element */
const loadingIndicatorRef = ref<HTMLDivElement>()

/** Indicate that the progress bar is currently loading or not */
const isRunning = ref(false)

/** Used internally in CSS to continue the bar if finished early */
const widthBeforeEnd = ref('100%')

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

/** Watch store for updates */
watch(() => progressBarStore.isLoading, isLoading => {
    if (isLoading) {
        start()
    } else {
        finish()
    }
})

/** Computed loading duration for the progress bar */
const duration = computed(() => {
    return isRunning.value ? props.duration : 0
})

/** Computed gradient string value for CSS use */
const gradient = computed(() => {
    const colorLength = props.colors.length

    if (props.colors && colorLength > 1) {
        /** Produces values akin to `#fff 0%, #ddd 50%, #bbb 100%` (n=3) */
        const colors = props.colors.map((color, idx) => {
            return `${color} ${(idx / (colorLength - 1) * 100).toFixed(3)}%`
        })

        return `linear-gradient(to right, ${colors.join(', ')})`
    } else if (props.colors) {
        return props.colors[0]
    } else {
        return 'rgb(0, 220, 130)'
    }
})

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Finish the current progress bar's progress
 */
function finish() {
    if (loadingIndicatorRef.value) {
        widthBeforeEnd.value = `${loadingIndicatorRef.value.clientWidth}px`
    }
    setRunning(false)
}

/**
 * Start or finish the progress bar
 */
function setRunning(running = true) {
    if (running) {
        isRunning.value = true
    } else {
        isRunning.value = false
    }
}

/**
 * Start the progress bar loading
 */
function start() {
    setRunning()
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

if (props.nuxtHookEnabled) {
    nuxtApp.hook('page:start', start)
    nuxtApp.hook('page:finish', finish)
}

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////

defineExpose({
    finish,
    start,
})
</script>

<template>
    <div :class="isRunning ? 'ongoing' : 'finish'"
         class="loadingIndicator"
         ref="loadingIndicatorRef">
    </div>
</template>

<style scoped>
.loadingIndicator {
    background-image: v-bind("gradient");
    position: fixed;
    z-index: 90;
}

.ongoing {
    animation: start v-bind("duration + 'ms'") ease-out forwards;
    height: v-bind("props.heightWithUnit");
}

.finish {
    animation: finish v-bind("props.durationToHide + 'ms'") linear forwards;
}

@keyframes start {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes finish {
    0% {
        height: v-bind("props.heightWithUnit");
        width: v-bind("widthBeforeEnd");
    }

    50% {
        height: v-bind("props.heightWithUnit");
        width: 100%;
    }

    99.99% {
        height: 0;
        width: 100%;
    }

    100% {
        height: 0;
        width: 0;
    }
}
</style>
