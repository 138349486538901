<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////
import { storeToRefs } from 'pinia';
import { useModalStore } from '~/stores/modal';
import { Icon } from '@iconify/vue'
import { directive as vTippy } from 'vue-tippy';

const modalStore = useModalStore()
//////////////////////////////////////////////////////@  Props & Emits
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////@  Variables
//////////////////////////////////////////////////////////////////////
const {
    asyncStatus,
    closeByBackdropEnabled,
    content,
    showModal,
    showModalFooter,
    cssWidth,
    cssHeight
} = storeToRefs(modalStore)

const modalRef = ref<HTMLElement>()
/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////
watch(() => showModal.value, async isVisible => {
    // Wait for the modal v-if to update after isVisible change
    await nextTick()

    // Focus on the modal for making 'Esc' key closing work, when modal is shown
    if (isVisible && modalRef.value) {
        modalRef.value.focus()
    }
})
//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Event handler for click event on the modal background/black screen
 */
function onBackdropClick() {
    if (closeByBackdropEnabled.value) {
        modalStore.closeModal()
    } else {
        useNuxtApp().$modal.show({
            type: 'warning',
            title: 'Are you sure that you want to close this window?',
            body: 'Any unsaved data will be lost!',
            primary: {
                label: 'Close',
                theme: 'red',
                action: modalStore.closeModal,
            },
            secondary: {
                label: 'Cancel',
                theme: 'white',
                action: () => { },
            },
        })
    }
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <div v-if="showModal"
         :class="{ 'cursor-pointer': closeByBackdropEnabled }"
         tabindex="-1"
         :aria-hidden="!showModal"
         class="fixed flex top-0 left-0 right-0 z-40 w-full h-full overflow-auto bg-slate-900/95 align-middle items-center"
         ref="modalRef"
         @keyup.esc.exact.self="modalStore.closeModal"
         @click="onBackdropClick">
        <div :class="`cursor-default w-screen max-w-screen-2xl relative min-w-6/12 m-auto p-6 ${cssWidth} ${cssHeight}`">
            <div @click.stop
                 class="vi-modal rounded-lg flex-1 min-w-9/12 shadow bg-white dark:bg-gray-800 dark:border-gray-700 ">
                <div class="vi-modal-title flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-2xl font-semibold text-gray-900 dark:text-white">
                        {{ asyncStatus.isResolved ? content.title : 'Loading...' }}
                    </h3>
                    <button @click="modalStore.closeModal"
                            type="button"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal"
                            v-tippy="'Press \'Esc\' key to close'">
                        <Icon icon="material-symbols:close"
                              class="w-6 h-6"></Icon>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <div class="vi-modal-body p-6 space-y-6">
                    <template v-if="!asyncStatus.isResolved">
                        <VueSpinner class="mx-auto w-fit"
                                    :gridLoader="true" />
                    </template>
                    <template v-else-if="'CRUD' === modalStore.type">
                        <IwForm :myForm="content.form!"
                                :on-submit="content.onSubmit"></IwForm>
                    </template>
                    <template v-else-if="'component' === modalStore.type">
                        <component :is="content.component"
                                   :params="content.componentParams"></component>
                    </template>
                    <template v-else>
                        <slot name="body">{{ content.body }}</slot>
                    </template>
                </div>

                <template v-if="showModalFooter">
                    <div
                         class="vi-modal-footer flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button data-modal-hide="defaultModal"
                                type="button"
                                class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">I
                            accept</button>
                        <button data-modal-hide="defaultModal"
                                type="button"
                                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
                    </div>
                </template>
            </div>
            <BackToTop v-if="modalRef"
                       :scrollableElement="modalRef" />
        </div>
    </div>
</template>

<style scoped>
</style>
