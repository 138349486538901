<script setup lang='ts'>
///////////////////////////////////////////////@  Import, Types & meta
//////////////////////////////////////////////////////////////////////

import { Icon } from '@iconify/vue'
import { directive as vTippy } from 'vue-tippy'
import { IwFormColor } from '../../../utils/IwFormColor'

type ColorObj = {
    /** The colour in string form (E.g. '#fff', 'rgb(0,0,0)', 'rgba(0,0,0,0.5)') */
    value: string,
    /** The name of the colour (E.g. 'Red') */
    label?: string,
}

///////////////////////////////////////////@  Props, Emits & Variables
//////////////////////////////////////////////////////////////////////

const emit = defineEmits(['change'])

const props = defineProps({
    /** A list of color to be displayed. (E.g. colorList: ['#fff', 'rgb(0,0,0)', '#ffffff88']) */
    colorList: {
        type: Array as PropType<Array<ColorObj | string>>,
        default: [],
    },
    /**
     * Show or hide the ColorSelector component
     */
    hidden: {
        type: Boolean,
        default: true,
    },
    /*
     * An IwFormColor readable string indicating which colour should be selected
     * in the current component
     *
     * This value will be matched against the current colour list and the
     * matching colour will be selected in the current component.
     */
    hintSelectedColor: {
        type: String,
        default: '',
    },
    /**
     * Whether to replace the default colour list with the supplied colorList
     *
     * Default: `false`
     */
    replaceDefault: {
        type: Boolean,
        default: false,
    },
    /**
     * Show a 'bx:block' icon for removing the currently applied colour
     *
     * An invisible colour will be emitted (E.g. '#fff0').
     * {@link Color.isTransparent} may be used to check if the colour is given.
     */
    showClearColor: {
        type: Boolean,
        default: true,
    }
})

/** The current selected colour's value as an IwFormColor instance */
const currentSelection = ref<IwFormColor>(IwFormColor.transparent)

const defaultColorList: IwFormColor[] = [
    new IwFormColor(0x1ABC9C, 0xFF, 'Strong Cyan'),
    new IwFormColor(0x2ECC71, 0xFF, 'Emerald'),
    new IwFormColor(0x3498DB, 0xFF, 'Bright Blue'),
    new IwFormColor(0x9B59B6, 0xFF, 'Amethyst'),
    new IwFormColor(0x4E5F70, 0xFF, 'Grayish Blue'),
    new IwFormColor(0xF1C40F, 0xFF, 'Vivid Yellow'),
    new IwFormColor(0x16A085, 0xFF, 'Dark Cyan'),
    new IwFormColor(0x27AE60, 0xFF, 'Dark Emerald'),
    new IwFormColor(0x2980B9, 0xFF, 'Strong Blue'),
    new IwFormColor(0x8E44AD, 0xFF, 'Dark Violet'),
    new IwFormColor(0x2C3E50, 0xFF, 'Desaturated Blue'),
    new IwFormColor(0xF39C12, 0xFF, 'Orange'),
    new IwFormColor(0xE67E22, 0xFF, 'Carrot'),
    new IwFormColor(0xE74C3C, 0xFF, 'Pale Red'),
    new IwFormColor(0xECF0F1, 0xFF, 'Bright Silver'),
    new IwFormColor(0x95A5A6, 0xFF, 'Light Grayish Cyan'),
    new IwFormColor(0xDDDDDD, 0xFF, 'Light Gray'),
    new IwFormColor(0xFFFFFF, 0xFF, 'White'),
    new IwFormColor(0xD35400, 0xFF, 'Pumpkin'),
    new IwFormColor(0xC0392B, 0xFF, 'Strong Red'),
    new IwFormColor(0xBDC3C7, 0xFF, 'Silver'),
    new IwFormColor(0x7F8C8D, 0xFF, 'Grayish Cyan'),
    new IwFormColor(0x999999, 0xFF, 'Dark Gray'),
    new IwFormColor(0x000000, 0xFF, 'Black'),
]

/** The colour list that is currently displayed */
const colorListInUse: Ref<Array<IwFormColor>> = ref([])

/////////////////////////////////////////////////@  Computed & Watches
//////////////////////////////////////////////////////////////////////

watch(() => props.hintSelectedColor, color => {
    if (color) {
        currentSelection.value = IwFormColor.initFromString(color)
    }
})

/**
 * Watch for changes to the passed in colorList
 */
watch(() => props.colorList, () => {
    const lookup = new Set()

    const convertedCustomList = convertCustomColorList([...props.colorList])

    // Remove any subsequent duplicating colours
    const uniqueCustomList = convertedCustomList.filter(color => {
        if (!lookup.has(color.rawValue)) {
            lookup.add(color.rawValue)
            return true
        } else {
            return false
        }
    })

    if (props.replaceDefault) {
        colorListInUse.value = uniqueCustomList
    } else {
        // A lookup table for checking custom list colours against the default colours
        const defaultListLookup = new Set(
            defaultColorList.map(color => color.rawValue)
        )

        // Remove any colours that already exist in default colour list
        colorListInUse.value = defaultColorList.concat(
            uniqueCustomList.filter(color =>
                !defaultListLookup.has(color.rawValue)
            )
        )
    }
}, { immediate: true })

//////////////////////////////////////////////////////////@  Functions
//////////////////////////////////////////////////////////////////////

/**
 * Used for converting colour passed in by props from parent component
 *
 * @param colorList The list of colour
 */
function convertCustomColorList(colorList: Array<ColorObj | string>): Array<IwFormColor> {
    return colorList.map(color => typeof color === 'string'
        ? IwFormColor.initFromString(color)
        : IwFormColor.initFromString(color.value, color.label)
    )
}

/**
 * Handles custom colour picker change. New colour will be added to the list.
 *
 * If a text is selected, the colour will be automatically applied.
 */
function insertColor(ev: Event) {
    const colorStr = (ev.target as HTMLInputElement).value
    const newColor = IwFormColor.initFromString(colorStr)

    /** The index of the colour that matches the new colour, if exist */
    const colorIndexInList = colorListInUse.value
        .findIndex(color =>
            color.rawValue === newColor.rawValue
            && color.alphaValue === newColor.alphaValue
        )

    if (colorIndexInList === -1) {
        colorListInUse.value.push(newColor)
    }

    if (document.getSelection()?.toString()) {
        switchColor(colorStr)
    } else {
        currentSelection.value = newColor
    }
}

/**
 * Handles color icons click event
 */
function switchColor(value: string) {
    currentSelection.value = IwFormColor.initFromString(value)
    emit('change', currentSelection.value)
}

/////////////////////////////////////////////////////////@  Lifecycles
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////@ Initialization
//////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////@  Export & Expose
//////////////////////////////////////////////////////////////////////
</script>

<template>
    <Transition name="fade">
        <div v-show="props.hidden"
             class="iwFormEditorDropdown"
             title=""> <!-- Stop title inheritance -->
            <ul class="iwFormEditorColorList">
                <li v-for="(color, key) in colorListInUse"
                    :key="key"
                    :class="{
                        'active': color.toHex() === currentSelection.toHex()
                    }"
                    :data-is-dark="color.darkenBy(IwFormColor.initFromHex('#808080')).rawValue === 0"
                    :style="{
                        backgroundColor: color.toHex(),
                        borderColor: color.darkenBy(IwFormColor.initFromHex('#222')).toHex()
                    }"
                    v-tippy="color.label || color.toHex()"
                    @click="switchColor(($event.target as HTMLLIElement).style.backgroundColor)">
                </li>
                <li v-if="props.showClearColor"
                    class="border-none"
                    v-tippy="'Clear'"
                    @click="switchColor(IwFormColor.transparent.toHex())">
                    <Icon class="iwFormEditorColorListClearColor"
                          icon="bx:block" />
                </li>
            </ul>
            <input class="iwFormEditorColorListCustomColor"
                   name="Custom color"
                   type="color"
                   value="#ffffff"
                   v-tippy="{
                       content: 'Choose and press \'Enter ↵\' to add colour',
                       placement: 'right',
                   }"
                   @change="insertColor" />
        </div>
    </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .25s
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0
}
</style>
